import { useEffect, useRef, useState } from 'react';
import { getImgPath } from 'ybcommon/ybutils/common/common';

/**
 * @description 优先队列元素
 */
interface QueueEleInstanceInfo {
  id: symbol;
  // priority: number;
  effect: () => void;
}

/**
 * @description 队列
 */
class DisplayPriorityQueue {
  static queue: QueueEleInstanceInfo[] = [];

  static addEle(ele: QueueEleInstanceInfo) {
    DisplayPriorityQueue.queue.unshift(ele);
    // DisplayPriorityQueue.queue.sort((a, b) => b.priority - a.priority);
    for (const comp of DisplayPriorityQueue.queue) {
      comp?.effect?.();
    }
  }

  static removeEle(id: symbol) {
    DisplayPriorityQueue.queue = DisplayPriorityQueue.queue.filter((ele) => ele.id !== id);
    for (const comp of DisplayPriorityQueue.queue) {
      comp?.effect?.();
    }
  }

  static getFirstEle() {
    return DisplayPriorityQueue.queue?.[0];
  }
}

interface SmallHandProps {
  style?: React.CSSProperties;
  // priority: number;
  // handImg可以传空字符串，仍旧会排在展示队列中
  handImg?: string;
  className?: string;
  onClick?(): void;
  id?: string;
}

// const priority = 1;

/**
 * @description 可抢占可归还小手，全局只展示一个，后来的请求先展示。释放后归还给下一优先级的小手
 * @param props
 * @returns
 */
export default function SmallHandPreemptible(props: SmallHandProps) {
  // const { priority } = props;
  const ref = useRef<symbol>(Symbol('SmallHandPreemptible'));
  const currentId = ref.current;
  const [dispay, setDisplay] = useState(false);

  useEffect(() => {
    DisplayPriorityQueue.removeEle(currentId);
    DisplayPriorityQueue.addEle({
      id: currentId,
      // priority,
      effect: () => {
        try {
          setDisplay(DisplayPriorityQueue.getFirstEle()?.id === currentId);
        } catch (error) {
          console.error(error);
        }
      },
    });
    return function onUnmout() {
      DisplayPriorityQueue.removeEle(currentId);
    };
  }, []);

  const { handImg, className, style, onClick, id } = props;
  return (dispay && handImg) ? (
    <img
      id={id}
      style={style}
      src={getImgPath(handImg)}
      className={className}
      alt=""
      onClick={onClick}
      data-clickable="true"
    />
  ) : null;
}
