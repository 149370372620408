import React from 'react';
import stores from 'src/store';
import { isWeMini } from 'common/utils/device';
import { openHrefUrl } from 'store/UrlTools';
import { isShouxin, isCN } from 'ybcommon/ybutils/common/company';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { CommonUtil as tool } from 'fe-yb-tools';
import { jumpToMiniProgram } from './jumpUtil';

/** *************** 产品判断相关 ******************* */

/**
 * 获取通过简称获取0+长险产品ID
 * @param {string} abb 产品简称
 * @returns {string} 产品ID
 */
export const getCombineIdByAbb = (abb) => {
  const { coreData = {} } = stores.userStore;
  const { combineAbbrMap = {} } = coreData;
  return combineAbbrMap[abb];
};

/**
 * 获取通过简称获取0+12产品ID
 * @param {string} abb 产品简称
 * @returns {string} 产品ID
 */
export const getComposeIdByAbb = (abb) => {
  const { coreData = {} } = stores.userStore;
  const { composeAbbrMap = {} } = coreData;
  return composeAbbrMap[abb];
};

// 设置判断产品的方法
export const judgeProduct = (insuranceId, productAbb) => {
  if (!insuranceId) return false;
  const { userStore } = stores;
  const { coreData } = userStore || {};
  const { productAbbrMap = {} } = coreData || {};
  return productAbbrMap[productAbb] === insuranceId;
};

// 不支持全年保费（批改的产品）
export const notAllowYear = (insuranceId) => judgeProduct(insuranceId, 'tkzxbw21mjzbz')
  || judgeProduct(insuranceId, 'tkzxbw21mjzj1')
  || judgeProduct(insuranceId, 'tkzxbw21mjzj2')
  || judgeProduct(insuranceId, 'tkzxbw21bz')
  || judgeProduct(insuranceId, 'tkzxbw21j1')
  || judgeProduct(insuranceId, 'tkzxbw21j2');

/**
 * 获取产品简称
 * @param {*} insuranceId 保险id
 * @returns { productType: string, insuranceAbb: string }
 * productType: productAbb-单一险 combineAbb-组合险 coupleAbb-均分险 ''-没有查到
 * insuranceAbb: 保险简称, ''-没有查到
 */
export const getAbbInfoByInsuranceId = (insuranceId) => {
  const { coreData = {} } = stores.userStore;
  const { productAbbrMap = {}, combineAbbrMap = {}, coupleAbbrMap = {}, composeAbbrMap = {} } = coreData;
  let insuranceAbb;

  /** 查询0+12产品简称 */
  Object.keys(composeAbbrMap).forEach((key) => {
    if (composeAbbrMap[key] === insuranceId) {
      insuranceAbb = key;
    }
  });
  if (insuranceAbb) return { productType: 'composeAbb', insuranceAbb };

  // 查询均分简称
  Object.keys(coupleAbbrMap).forEach((key) => {
    if (coupleAbbrMap[key] === insuranceId) {
      insuranceAbb = key;
    }
  });
  if (insuranceAbb) return { productType: 'coupleAbb', insuranceAbb };
  // 查询均分简称
  Object.keys(combineAbbrMap).forEach((key) => {
    if (combineAbbrMap[key] === insuranceId) {
      insuranceAbb = key;
    }
  });
  if (insuranceAbb) return { productType: 'combineAbb', insuranceAbb };
  Object.keys(productAbbrMap).forEach((key) => {
    if (productAbbrMap[key] === insuranceId) {
      insuranceAbb = key;
    }
  });
  if (insuranceAbb) return { productType: 'productAbb', insuranceAbb };

  if (!insuranceAbb) { // type没有值，则埋点上报
    trackEvent('h5_no_insuranceId');
  }
  return {
    productType: '',
    insuranceAbb: '',
  };
};

/**
 * 通过abb获取insuranceId
 *
 * @param {*} abb
 * @param {{ isCompose: boolean; isCombine: boolean; isCouple: boolean; }} param0
 * @param {*} param0.isCompose 0 + 短
 * @param {*} param0.isCombine 0 + 长
 * @param {*} param0.isCouple 均分组合
 * @returns {*}
 */
export const getInsuranceIdByAbb = (abb, { isCompose, isCombine, isCouple }) => {
  if (!abb) return '';
  const { coreData = {} } = stores.userStore;
  const { productAbbrMap = {}, combineAbbrMap = {}, coupleAbbrMap = {}, composeAbbrMap = {} } = coreData;
  if (isCompose) return composeAbbrMap[abb];
  if (isCombine) return combineAbbrMap[abb];
  if (isCouple) return coupleAbbrMap[abb];
  return productAbbrMap[abb];
};

/**
 * 通过abb获取insuranceId
 *
 * @deprecated 请使用新的`getInsuranceIdByAbb`函数代替。
 */
export const getInsuranceId = (abb, isCombine = false, isCouple = false, isCompose = false) => getInsuranceIdByAbb(abb, { isCompose, isCombine, isCouple });

/**
 * 获取api保险类型
 */
export const getInsuranceType = (insuranceId) => {
  const { coreData = {} } = stores.userStore;
  const { productAbbrMap = {} } = coreData;
  let type;
  Object.keys(productAbbrMap).forEach((key) => {
    if (productAbbrMap[key] === insuranceId) {
      type = key;
    }
  });
  if (!type) { // type没有值，则埋点上报
    trackEvent('h5_no_insuranceId');
  }
  return type;
};

/** *************** 落地页跳转相关 ******************* */
/**
 * 根据id和参数跳转至相应api保险的页面
 */
// eslint-disable-next-line no-unused-vars
export const jumpToDetail = (insuranceId, history, params = {}, fromGift = false, isReplace = false, isCombineProduct = false, isComposeProduct = false) => {
  // 生成url参数字符串
  const sdata = [`insuranceId=${insuranceId}`];
  // eslint-disable-next-line guard-for-in
  for (const attr in params) {
    sdata.push(`${attr}=${params[attr]}`);
  }

  let _urlParamStr = sdata.join('&');
  const shuntId = window.ybParams?.shuntId;
  if (shuntId) {
    _urlParamStr = `${_urlParamStr}&shuntId=${shuntId}`;
  }
  const localJump = isReplace ? history.replace : history.push;
  if (insuranceId === getInsuranceId('tkzjzx_xa')) {
    history.push(`/jkjgift?${_urlParamStr}`);
  } else {
    // 如果推荐的是0+12产品 则跳转0+12落地页
    if (isComposeProduct) {
      localJump(`/cargo/compose?${_urlParamStr}`);
      return;
    }
    // 如果推荐的是0+长险产品 则跳转0+长险落地页
    if (isCombineProduct) {
      localJump(`/cargo/combine?${_urlParamStr}`);
      return;
    }
    localJump(`/cargo/detail?${_urlParamStr}`);
  }
};

export const jumpToDetailByType = (abb, history, params, fromGift = false, isReplace = false) => {
  // 是0+1产品
  const isCombineProduct = Boolean(getCombineIdByAbb(abb));
  // 是0+12产品
  const isComposeProduct = Boolean(getComposeIdByAbb(abb));

  const insuranceId = getInsuranceIdByAbb(abb, { isCombine: isCombineProduct, isCompose: isComposeProduct });

  jumpToDetail(insuranceId, history, params, fromGift, isReplace, isCombineProduct, isComposeProduct);
};

/**
 * 根据jumpType类型跳转
 * @param {*} jumpUrl 跳转链接
 * @param {*} jumpType 跳转类型 为1时使用openHrefUrl跳转 1——站内H5 2——站外H5 3——小程序path
 * @returns
 */
export const jumpByType = (jumpUrl, jumpType, isReplace = false) => {
  if (!jumpUrl) return;
  if (+jumpType === 1) {
    openHrefUrl(jumpUrl, isReplace);
  } else if (isReplace) {
    window.location.replace(jumpUrl);
  } else if (+jumpType === 3) {
    jumpToMiniProgram(jumpUrl);
  } else {
    window.location.href = jumpUrl;
  }
};

/** *************** 渠道判断相关+推广上报 ******************* */

/**
 * 第三方推广涉及到回传js接入(dep.js里均做了统一处理)及相应上报事件
 * 目前在赠险领取处均调用了该函数（搭售里创建订单单独做了处理，也已加上）
 *
 * @param {*} channel 渠道号
 * @param {*} params 传参
 * @returns 无
 */
export const doTuiguangEvent = (channel, params) => {
  if (__DEV__) return;
  // 此处使用的__
  // eslint-disable-next-line no-undef
  if (__uploadTg__) __uploadTg__(channel, stores.userStore.userId, params);
};

/**
 * 判断IP用户 目前特殊地方的ip，批增默认全部指定非默认升级 可回溯版本固定
 * @param {*}
 */
export const checkLimitedCity = () => {
  const { isLimitedCity = 0 } = stores.userStore;
  if (+isLimitedCity === 1) {
    return true;
  }
  return false;
};

/** *************** 小程序签约支付支持判断 ******************* */

/**
   * 是否支持去支付按钮出现
   *
   * @param {*} unpaidEntrance 待支付入口配置
   * @returns 是否打开去支付
   */
export const isSupportPay = (unpaidEntrance) => {
  const { isWeChatMiniApp } = stores.userStore;
  const unpaidEntranceConfig = tool._parseJSON(unpaidEntrance);

  if (+isWeChatMiniApp === 1 || +isWeChatMiniApp === 5) {
    // 主站小程序，首信小程序
    return unpaidEntranceConfig.includes(2);
  }
  // h5
  return unpaidEntranceConfig.includes(1);
};

/**
   * 是否支持重新签约和自动续保
   *
   * @param {*} insuranceId 产品id
   * @param {*} companyId 保险公司id
   * @param {*} isCashier 是否自有支付，1:保司首款；2:自有收款
   * @returns 是否打开去支付
   */
export const isSupportResign = (insuranceId, companyId, isCashier) => {
  const { isWeChatMiniApp } = stores.userStore;
  const supportCompanys = [1, 2, 4, 25, 26, 27, 28, 29, 30, 31, 32, 34]; // 支持主站小程序支付的保司id-泰康/众安/华泰/阳光/华农/大家/人保财/史带/平安财/大地/利宝/太平

  if (+companyId === 15) {
    // 弘康长险，不能继续支付
    return false;
  }

  if (+isWeChatMiniApp !== 1) {
    // 非主站、快手、抖音小程序
    return true;
  }
  // 主站小程序，且为指定保司||自有支付的产品；
  if (+isWeChatMiniApp === 1 || +isWeChatMiniApp === 5) {
    if (supportCompanys.indexOf(+companyId) !== -1 || +isCashier === 2) return true;
  }
  return false;
};

/** *************** 客服相关 ******************* */

/**
 * @name: 获取客服链接 售后（2,1）；售前（8，2） 默认售后
 * @param {*} _channel
 * @param {*} robotid
 * @param {*} isCustomer 是否为客服中间页 true/false
 * @return {*}
 */
export const getKFLink = (_channel = 2, robotid = 1, isCustomer = false) => {
  if (isCustomer) {
    return `/customer?channelid=${_channel}&robotid=${robotid}`;
  }
  const { userStore } = stores;
  const { userId, isNewKefuLink } = userStore;
  // 根据开关状态判断客服链接版本
  const kfVersion = isNewKefuLink ? 'v6' : 'v2';
  let kfLink = `https://yb1.sobot.com/chat/h5/${kfVersion}/index.html?sysnum=d04867183eaf4c75b29e132d7d9c2648&channelid=${_channel}&robotid=${robotid}`;
  // 可以&params={"key":"value"}&partnerid=zc，params为自定义字段，可不传
  if (userId) {
    kfLink = `${kfLink}&partnerid=${userId}`;
  }
  return kfLink;
};

/**
 * @name: 跳转客服页
 * @param {*} _channel 客服channelid
 * @param {*} robotid 客服机器人id
 * @param {*} trackParams 埋点参数，不需要埋点则不传
 * @param {*} isCustomer 是否跳客服中间页 true/false
 * @return {*}
 */
export const goToKF = (_channel, robotid, trackParams = null, isCustomer = false) => {
  if (trackParams && trackParams.page) {
    trackEvent('online_consultation_click', { online_consultation_page: trackParams.page, product_ID: trackParams.insuranceId });
  }
  if (isCustomer) {
    openHrefUrl(`/customer?channelid=${_channel}&robotid=${robotid}`);
    return;
  }
  const kfLink = getKFLink(_channel, robotid);
  window.location.href = kfLink;
};

/**
 * @name: 理赔页-跳转客服链接
 * @param {*} channelid 指定channelid
 * @return {*}
 */
export const goToClaimKF = (channelid = '') => {
  let robotId = 3;
  let channelId = isWeMini() ? 80 : 28;
  if (isShouxin()) {
    robotId = 1;
    channelId = isWeMini() ? 81 : 57;
  }
  if (channelid) channelId = channelid;
  goToKF(channelId, robotId, null, true);
};

// 拨打电话
export const callPhone = (phone) => {
  if (!phone) return;
  window.location.href = `tel:${phone}`;
};

/** *************** 其他 ******************* */

/**
 * 根据pathname获取本地存储数据key
 */
export const getZxLocalStorageKey = () => '_DATA_ZX_';

// 根据生日获取年龄，type 1：按当前日期算 2：赠险按生效日计算，当前日期加一天
export const getAgeFromBirth = (birth, type = 1) => {
  if (!birth || birth.length < 6) {
    return -1;
  }
  const tomorrow = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
  const curDate = type === 2 ? tomorrow : new Date();
  const curYear = curDate.getFullYear();
  const curMonth = curDate.getMonth() + 1;
  const curDay = curDate.getDate();

  const birthYear = +birth.substring(0, 4);
  const birthMonth = +birth.substring(4, 6);
  const birthDay = +birth.substring(6, 8);

  let age = curYear - birthYear - 1;
  if (birthMonth < curMonth || (birthMonth === curMonth && birthDay <= curDay)) {
    age++;
  }
  return age;
};

/**
   * 获取详细的年龄
   * @param {*} birth 生日
   * @param {*} delayDays 年龄计算基础 0 为投保日 生效日为t+1 则传1
   * @returns 年龄map { unit: 单位，1 天 2 年； num 数值 }
   */
export const getAgeInfoFromBirth = (birth, delayDays = 0) => {
  if (!birth || birth.length < 6) {
    return null;
  }
  const birthYear = +birth.substring(0, 4);
  const birthMonth = +birth.substring(4, 6);
  const birthDay = +birth.substring(6, 8);
  const oneDayTime = 24 * 60 * 60 * 1000;
  const delayDateTime = new Date().getTime() + oneDayTime * delayDays;
  const nowDate = new Date(delayDateTime);
  const nowYear = nowDate.getFullYear();
  const nowMonth = nowDate.getMonth() + 1;
  const nowDay = nowDate.getDate();

  let age = nowYear - birthYear - 1;
  let unit = 2; // 年
  if (birthMonth < nowMonth || (birthMonth === nowMonth && birthDay <= nowDay)) {
    // 生日的当天算一年
    age++;
  }
  if (age === 0) {
    const diff = new Date(`${nowYear}/${nowMonth}/${nowDay}`) - new Date(`${birthYear}/${birthMonth}/${birthDay}`);
    age = Math.floor(diff / oneDayTime);
    unit = 1; // 天
  }
  return { num: age, unit };
};

// 是否是长险配置化产品
export const isLongProduct = (insuranceId) => {
  const { userStore } = stores;
  const { coreData } = userStore;
  const { newLongInsuranceId = '' } = coreData || {};
  if (newLongInsuranceId && newLongInsuranceId.split(';').indexOf(insuranceId) > -1) return true;
  return false;
};

// 是否是短险险配置化产品2.0
export const isShortProduct = (insuranceId) => {
  const { userStore } = stores;
  const { coreData } = userStore;
  const { shortInsuranceId = '' } = coreData || {};
  if (shortInsuranceId.split(';').indexOf(insuranceId) > -1) return true;
  return false;
};

// 是否是group1渠道（站内），page1Abtest、page2Abtest
export const isGroup1 = (channel, position) => {
  const internalChannelIds = stores.userStore.internalChannelIds;
  const group1Reg = new RegExp(`_(${internalChannelIds})_`);
  if (channel || position) {
    return group1Reg.test(channel) || group1Reg.test(position);
  }
  return true;
};

// 判断是否是中华联合产品
export const isZHLHProduct = (insuranceId) => {
  const { userStore } = stores;
  const { zhCorrectionInsurance } = userStore;
  if (zhCorrectionInsurance && zhCorrectionInsurance.indexOf(insuranceId) > -1) {
    return true;
  }
  return false;
};

/**
 * 展示ini配置协议
 * @param {*} procotolName 协议名称
 */
export const showProcotolPage = (procotolName) => {
  const { protocolPage } = stores.userStore;
  if (protocolPage[procotolName]) {
    const { title = '', protocolId = '' } = protocolPage[procotolName];
    return { title, protocolId };
  }
  return false;
};

/**
 * 是否是华农商户号产品
 * @param {*} insuranceId
 */
export const isHNProduct = (insuranceId) => {
  if (__PUB__) {
    return judgeProduct(insuranceId, 'hnbwylxgz2hnshwx') || judgeProduct(insuranceId, 'hnbwylxbzsjhnshwx');
  }
  return judgeProduct(insuranceId, 'hnbwylxgz2hnsh') || judgeProduct(insuranceId, 'hnbwylxbzsjhnsh');
};

/**
 * @method 是否是家财险（仅支持给本人投保）
 * @returns {boolean} true - 是,false - 否
 * @description 改为根据cms端配置的“投被保人关系类型”进行判断，家财险会只配置本人投保，即接口返回['1']，其他情况还是默认的四个tab，即接口返回['1','2','3','4']，四个tab都能投保
 *    prd: https://yuanbaoshuke.feishu.cn/docx/RlVhdYkaKoO5X5xRwFJcHpLRnZg
 */
export const isHomeInsuranceProduct = () => {
  const { applicantInsurantRelations } = stores.userStore;
  return applicantInsurantRelations.length === 1 && applicantInsurantRelations[0] === '1';
};

/**
 *  跟单存储协议id+版本
 * @param 需要跟单存储的协议
 * @returns 协议id+版本号
 */
export const getProductContracts = (contractsNew, contractsTc, contractsXb = []) => {
  const productContracts = [];
  if (contractsNew?.length) {
    contractsNew.forEach((item) => {
      productContracts.push({
        value: item.value,
        version: item.version || 0,
      });
    });
  }
  if (contractsTc?.length) {
    contractsTc.forEach((item) => {
      productContracts.push({
        value: item.value,
        version: item.version || 0,
      });
    });
  }
  if (contractsXb?.length) {
    contractsXb.forEach((item) => {
      productContracts.push({
        value: item.value,
        version: item.version || 0,
      });
    });
  }
  return productContracts;
};

/**
   *  给协议增加扣费协议
   * @param contractsNew 协议
   * @returns
   */
export const addKfAgreement = (contractsNew, ybCompanyId) => {
  if (!Array.isArray(contractsNew)) {
    return;
  }
  if (!isCN() && !isShouxin()) {
    return;
  }
  const cnId = '231206103732469LC662286';
  const sxId = '231206103732487LC690909';

  const kfAgreement = {
    value: ybCompanyId === 'yuanbao' ? cnId : sxId,
    key: '扣款授权确认书',
    type: 1,
  };

  if (contractsNew.findIndex((item) => item.key === kfAgreement.key) === -1) {
    contractsNew.push(kfAgreement);
  }
};

/**
   * 是否是推啊渠道
   * 增加万丈渠道
   */
export const xagiftContainsTaChannel = (channel) => {
  if (channel === '') {
    return false;
  }
  const items = channel.split('_');
  if ((items.length > 1 && items[1] === 'ta') || (items.length >= 3 && items[1] === 'wz' && items[2] === 'wfh')) {
    return true;
  }
  return false;
};

/**
 * todo
 * 根据组合产品id 获取组合产品简称
 * @param insuranceId 均分组合产品id
 */
export const getCoupleAbb = (insuranceId) => {
  const { coreData = {} } = stores.userStore;
  const { coupleAbbrMap = {} } = coreData;

  let type;
  Object.keys(coupleAbbrMap).forEach((key) => {
    if (coupleAbbrMap[key] === insuranceId) {
      type = key;
    }
  });
  if (!type) { // type没有值，则埋点上报
    trackEvent('h5_no_insuranceId');
  }

  return type;
};

/**
 * @description: 处理置灰统一方法
 * @param {*} gray
 * @return {*}
 */
export const handleGrayConfig = (gray) => {
  if (!gray?.configJson) return false;

  const json = JSON.parse(gray.configJson);

  const { accountType, envType, startTime, endTime } = json || {};
  const curTime = Date.now();
  const hasAccount = (isCN() && accountType.includes('1'))
    || (isShouxin() && accountType.includes('2'));
  const start = new Date(startTime.replace(/-/g, '/')).getTime();
  const end = new Date(endTime.replace(/-/g, '/')).getTime();
  if (hasAccount && envType.includes('1') && curTime >= start && curTime <= end) {
    return true;
  }

  return false;
};
