import { CloseOutline } from 'antd-mobile-icons';
import styles from './index.less';

interface BubblePopoverProps {
  visible: boolean;
  content: string;
  background?: string;
  // 气泡下边三角形的颜色
  arrowColor?: string;
  textColor?: string;
  onClick?(): void;
  onClose(): void;
}

export default function BubblePopover(props: BubblePopoverProps) {
  const { visible, content } = props;
  if (!visible || !content) {
    return null;
  }
  const { onClose, background, arrowColor, textColor, onClick } = props;

  // 三角形为伪类，故以css变量赋值
  if (arrowColor) {
    document.documentElement.style.setProperty('--bubble-popover-arrow-color', arrowColor);
  }
  return (
    <div
      className={styles['agree-content-tip']}
      style={{
        background,
        color: textColor,
      }}
    >
      <span onClick={onClick}>{content}</span>
      <span className={styles.close}>
        <CloseOutline onClick={onClose} />
      </span>
    </div>
  );
}
