/*
 * @Author: shixiaoxia
 * @Date: 2024-05-21 15:36:21
 * @LastEditors: chenqiusheng cqs_travis@163.com
 * @LastEditTime: 2025-02-05 15:25:33
 * @Description: gift/middle数据获取
 */
import { CommonHeader } from '../../../../fetch/base';
import { giftDetailPfreeConfig2Service } from './config2';
import { PfreeGiftDetailParam, PfreeGiftDetailResult } from './types';
import { CommonParams } from '../../../../types';

export const giftDetailPfreeService = async (
  giftParam: PfreeGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<PfreeGiftDetailResult> => {
  // 获取新配置赠险链路数据
  const giftDataResult = await giftDetailPfreeConfig2Service(giftParam, commonHeader);
  return {
    ...giftDataResult,
  };
};
