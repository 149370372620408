import classNames from 'classnames';
import SmallHandPreemptible from 'src/sys/product/components/SmallHandPreemptible';
import { pick } from 'lodash-es';
import styles from './index.less';

export interface IHandsGuideProps {
  /**
   * 2024-5-8 显示小手点击的动画，显示条件：
   * pzLink3HandRefactorAbtest version === 3 && handstyle === 2
   */
  showHandTap?: boolean;
  handImg?: string;
  handStyle?: React.CSSProperties;
  handClass?: any;
  /** 是否使用可抢占小手 */
  useSmallHandPreemptible?: boolean;
  onClick?: (e?) => void;
  id?: string;
}

/**
 * 渲染引导小手
 * @param props handImg: 小手的图片
 * @param props handStyle: 小手的样式，不同的地方渲染的小手位置可能不同，根据这个来调整
 * @returns img element
 */
const HandsGuide = (props: IHandsGuideProps) => {
  const { id, showHandTap = false, handImg = '', handClass = '', handStyle = {}, useSmallHandPreemptible = false, onClick } = props;

  return (
    <>
      {showHandTap && <div className={styles.tap} style={pick(handStyle, ['top', 'right', 'animationDuration', 'WebkitAnimationDuration'])} />}
      {!useSmallHandPreemptible && (
        <img
          id={id}
          alt="handsGuide"
          className={classNames(handClass, showHandTap ? styles.handTap : styles.hand)}
          style={handStyle}
          src={handImg || require('./hand.png')}
          onClick={onClick}
          data-clickable="true"
        />
      )}
      {useSmallHandPreemptible && (
        <SmallHandPreemptible
          id={id}
          className={classNames(handClass, showHandTap ? styles.handTap : styles.hand)}
          style={handStyle}
          handImg={handImg || require('./hand.png')}
          onClick={onClick}
        />
      )}
    </>
  );
};

HandsGuide.defaultProps = {
  showHandTap: false,
  handImg: '',
  handStyle: {},
  onClick: () => { },
  handClass: '',
};

export default HandsGuide;
