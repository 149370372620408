/**
 * 这个文件维护的概念对应的是一个和“主体”相关的需求：首信主体产品去“元保”化
 * 详见prd: https://yuanbaoshuke.feishu.cn/docx/AoiHdQFU1oM1scx3IwLc2yNdnif
*/

import { changeFavicon } from 'ybcommon/ybutils/common/common';
import { isCN, isShouxin, checkProductEntityByTenantCode } from 'ybcommon/ybutils/common/company';
import { checkProductEntity, productEntityAndDomainIsMatch } from './entity';

// 这个开关控制“去元保”的功能是否生效。
let removeYuanbaoSwitch = false;

/** 获取开关 */
export function getRemoveYuanbaoSwitch(): boolean {
  return removeYuanbaoSwitch;
}

/** 设置【开关】 */
export function setRemoveYuanbaoSwitch(ifOpen: boolean): void {
  removeYuanbaoSwitch = !!ifOpen;
}

/**
 * 这个白名单维护的路由对window.title的处理方式比较特殊。
 * 1. 在Html中，title会被设置为“持牌保险专家”。
 * 2. bff/start接口返回开关后，如果开关是false，那么会执行原来html中应该执行的逻辑（根据域名进行设置）。
 * 3. bff/start接口返回开关后，如果开关是true，此时什么都不做，进到具体的链路后会根据“产品主体”重新设置title。
 * 4. 进入链路拿到“产品主体”后，如果“产品主体”和域名一致，则根据“产品主体”设置title，否则什么也不做。
 *
 * 注意：window?.yb?.isUrlIsMatchWhiteList的赋值在/public的html文件中
 */
export function isUrlMatchWhiteList() {
  // @ts-ignore
  return window?.__yb__?.isUrlMatchWhiteList?.();
}

/** 对应prd中的描述：阿波罗配置的开关打开时，判断当前产品主体如为首信 */
export function isShouXinAndSwitchOpen() {
  return getRemoveYuanbaoSwitch() && checkProductEntity().isShouXin;
}

/** 阿波罗配置的开关打开 且 域名为首信 */
export function isShouXinByHostAndSwitchOpen() {
  return getRemoveYuanbaoSwitch() && isShouxin();
}

/** 阿波罗配置的开关打开 且 订单产品主体为首信 */
export function isShouXinByTenantCodeAndSwitchOpen(tenantCode: number) {
  return getRemoveYuanbaoSwitch() && checkProductEntityByTenantCode(tenantCode).isShouXin;
}

export function changeTitle(title) {
  // 跟pm加会沟通后，这个需求的结果页不修改title。详情见文件顶部所载的prd。
  if (window.location.pathname === '/resultNew/detail') return;
  document.title = title;
}

/**
 * 对应prd中的描述：
 * 阿波罗配置的开关打开时，判断如下（范围是上述现状梳理的范围）
 * 1. 域名和产品主体一致时，按域名展示：
 *   - 首信域名：首信，持牌保险专家（无logo）
 *   - 经纪域名：经纪，持牌保险专家（有logo）
 * 2. 域名和产品主体不一致：统一成“持牌保险专家”（无logo）
*/
export function handleTitleAndFavicon(): void {
  if (!getRemoveYuanbaoSwitch()) return;
  if (!isUrlMatchWhiteList()) return;
  if (!productEntityAndDomainIsMatch()) {
    changeTitle('持牌保险专家');
    return;
  }

  if (isShouxin()) {
    changeTitle('首信，持牌保险专家');
    return;
  }

  if (isCN()) {
    changeTitle('经纪，持牌保险专家');
    changeFavicon();
  }
}
