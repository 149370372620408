/*
 * @Author: liulongjie liulongjie@yuanbaobaoxian.com
 * @Date: 2023-07-24 17:54:29
 * @LastEditors: qiwei
 * @LastEditTime: 2025-01-23 19:29:13
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/order/ali/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  path: '/ali',
  routes: [
    { // 支付宝保单列表
      path: '/ali/order/list',
      component: () => import('./list'),
    },
    { // 支付宝历史保单
      path: '/ali/order/listH',
      component: () => import('./list'),
    },
    {
      path: '/ali/order/detail',
      component: () => import('./detail'),
    },
  ],
};
