/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { matchRoutes, renderRoutes } from 'react-router-config';
import { Toast } from '@yb/fe-component';
import { url } from 'utils';
import { isShouxin, getTitle } from 'ybcommon/ybutils/common/company';
import { TianjiFetch } from 'fetch';
import stores from 'store';
import { getCurHistoryAction } from 'ybcommon/ybutils/historyUtil';
import { updateChannel } from 'store/UrlTools';
import { WxShare } from 'component';
import { isUrlMatchWhiteList } from './utils/common/entity';

const USEYBLOADINGPAGE = [
  // 使用元保定制loading的页面
  '/',
  '/cargo/nonStandard',
  '/router/detail',
  '/cargo/login',
  '/cargo/middle',
  '/cargo/detail',
  '/pay/transition',
  '/resultNew/detail',
  '/resultNew/cxdetail',
  '/order/cxunpaid',
];

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: props.initialRoute,
    };
  }

  componentDidMount() {
    /**
     * 微信内，保险去支付的时候会在链接中添加参数，参数存在的时候弹出获取结果弹窗
     */
    const { route } = this.state;
    const { location } = this.props;
    const { toolStore } = stores;
    const params = url(window.location.href).params;
    this.resultsign = params ? params.resultsign : null;
    this.orderId = params ? params.orderId : null;
    // this.userIdencrypt = params ? params.userIdencrypt : null;
    this.isHT = params ? params.isHT : null;
    // 此处只有华泰+泰康1分钱意外才会走到这
    if (this.resultsign && this.orderId && this.isHT) {
      toolStore.showModalByType(
        100,
        {
          titleText: '是否已完成支付',
          confirmText: '已经完成',
          cancelText: '遇到问题',
          onConfirm: () => this._queryOrderStatus(),
          onCancel: () => this._queryOrderStatus(),
        },
        false,
      );
    }
    /**
     * 如果发现是从微信回调的页面，需要判断cookie是否含有hasInfo信息
     * 如果没有hasInfo需要先跳到完善信息页面，完善完了再跳转到回调页面
     * 否则直接跳转到回调页面
     */
    if (!route) this.match(location);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;
    if (location.pathname !== nextLocation.pathname
      || (
        (location.pathname === '/cargo/login' || location.pathname === '/cargo/detail' || location.pathname === '/order/reduce' || location.pathname === '/claim/detail' || location.pathname === '/logoff' || location.pathname === '/resultNew/cxdetail')
        && location.key !== nextLocation.key
      )) {
      // 处理前后链接的channel
      updateChannel(nextLocation, true);
      this.match(nextLocation);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !!nextState.route.path;
  }

  _queryOrderStatus = () => {
    const { history } = this.props;
    const { toolStore } = stores;
    toolStore.hideModal(false);
    TianjiFetch.loadingPost(
      {
        url: 'api/mall/bff/order/detail/version2/queryDetail',
        params: { orderId: this.orderId },
      },
      (data) => {
        const { orderStatus, checkFailedReason, insuranceId, orderId } = data;
        let newPath;
        switch (orderStatus) {
          case 1:
          case 4:
          case 7:
          case 3:
            // 现在 1 4 7都为成功  4 7是下单成功 1是购买成功，3待支付
            newPath = `/resultNew/detail?orderId=${orderId}`;
            history.push(newPath);
            break;
          default:
            toolStore.showPayModal({ content: checkFailedReason }, () => {
              history.push(`/cargo/detail?insuranceId=${insuranceId}`);
            });
            break;
        }
      },
      (err) => {
        Toast.fail(err);
      },
    );
  };

  match = (location) => {
    const { routes, history } = this.props;
    const curRoute = matchRoutes(routes, location.pathname)
      .filter((route) => route.match.isExact)
      .map((route) => route.route)[0];
    if (curRoute && curRoute.component) {
      if (curRoute.component.prototype instanceof Component || curRoute._isLoaded) {
        this.updateRoute(curRoute);
      } else {
        this.updateRoute();
        curRoute
          .component()
          .then((mod) => {
            curRoute.component = mod.default || mod;
            curRoute._isLoaded = true;
            this.updateRoute(curRoute);
          })
          .catch((err) => {
            console.log('======router====');
            console.log(err);
            console.log('==========');
            Toast.fail({ msg: '网络异常，请重试' });
            if (window.yb && window.yb.uplogCatchError) {
              const trackCode = err?.name === 'ChunkLoadError' ? 'chunkLoadError' : 'routerLoadError';
              window.yb.uplogCatchError(JSON.stringify({ code: trackCode, msg: { message: err?.message, stack: err?.stack ? err.stack.split(':') : '' } }));
            }
          });
      }
    } else {
      console.log(location);
      history.replace(`/error/404?tmp=${location.pathname}`);
    }
  };

  updateRoute = (curRoute) => {
    if (!curRoute || !curRoute.component) {
      this.setState({ route: {} });
    } else {
      window.__pathname = curRoute.path;
      // 隐藏客服悬浮按钮
      stores.toolStore.showKF = false;
      console.log('router page:', window.__pathname);
      // 同component不跳转的问题
      if (curRoute.path === '/cargo/login' || curRoute.path === '/cargo/detail' || curRoute.path === '/order/reduce' || curRoute.path === '/cargo/seodetail' || curRoute.path === '/claim/detail' || curRoute.path === '/logoff' || curRoute.path === '/resultNew/cxdetail') {
        curRoute.key = window.location.href;
      }
      this._updateTitle(curRoute);
      // 不展示元保定制loading的页面，隐藏loading
      if (USEYBLOADINGPAGE.indexOf(curRoute.path) === -1) {
        stores.toolStore.showYBLoading = false;
      }
      this.setState({ route: curRoute }, () => {
        if (document.scrollingElement) {
          if (getCurHistoryAction() === 'POP') {
            if (this.scrollTimer) {
              clearTimeout(this.scrollTimer);
              this.scrollTimer = null;
            }
            return;
          }
          this.scrollTimer = setTimeout(() => {
            document.scrollingElement.scrollTop = 0;
          }, 100);
        }
      });
    }
  };

  _updateTitle = (route) => {
    const { path, title } = route;
    if (title) {
      document.title = title;
    } else if (path) {
      const _p = path.toLowerCase();
      switch (_p) {
        case '/people/add':
          document.title = '添加家人';
          break;
        case '/people/edit':
          document.title = '编辑资料';
          break;
        case '/people/list':
          document.title = '我和家人';
          break;
        case 'rights/introdution':
          document.title = '元保会员';
          break;
        case '/health/detail':
        case '/health/video':
          document.title = '免费领60000元重疾保障';
          break;
        case '/claim':
          document.title = '理赔服务';
          break;
        case '/service/counselor':
          document.title = '我的专属顾问';
          break;
        case '/collect':
          document.title = '个人信息填写';
          break;
        case '/service/introduce':
          document.title = '1对1保险定制服务';
          break;
        case '/cargo/protectdetail':
          document.title = '保障详情';
          break;
        case '/order/detail':
        case '/pay/detail':
          document.title = '保单详情';
          break;
        case '/order/correct':
          document.title = '电子保单';
          break;
        case '/report/month':
          document.title = '月度保障报告';
          break;
        case '/welfare':
        case '/welfare/record':
          document.title = '福利保障卡';
          break;
        case '/jkjgift':
        case '/jkjgift/login':
        case '/jkjgift/confirm':
          document.title = '免费领60000元重疾保障';
          break;
        case '/unorder/reason':
          document.title = '退保原因';
          break;
        case '/unorder/service':
          document.title = '退保服务';
          break;
        case '/record/pay':
          document.title = '缴费记录';
          break;
        // case '/resign':
        //   document.title = '元保·百万医疗险';
        //   break;
        case '/question/submit':
          document.title = '发布问题';
          break;
        case '/question/detail':
          document.title = '问答详情';
          break;
        case '/order/xbdetail':
          document.title = '连续保障';
          break;
        case '/risktest/result':
        case '/risktest/question':
          break;
        case '/extra/service':
          document.title = '增值服务';
          break;
        case '/triger/detail':
          document.title = 'VIP用户福利大放送';
          break;
        case '/wallet/recharge':
          document.title = '话费充值';
          break;
        case '/wallet/list':
          document.title = '我的钱包';
          break;
        case '/triger/public':
          document.title = '关注公众号';
          break;
        case '/triger/award':
          document.title = '我的奖品';
          break;
        case '/triger/awardtemp':
          document.title = '我的奖品';
          break;

        case '/claim/service/online':
          document.title = '理赔指引';
          break;
        case '/claim/service/question':
          document.title = '理赔问答';
          break;
        case '/claim/service/record':
          document.title = '理赔记录';
          break;
        case '/claim/service/evaluate':
          document.title = '理赔评价';
          break;
        case '/claim/service/claiminfos':
          document.title = '理赔申请';
          break;
        case '/claim/service/result':
          document.title = '理赔进度';
          break;
        case '/claim/service/claimdetail':
          document.title = '理赔详情';
          break;
        case '/claim/service/materialsupplement':
          document.title = '资料补传';
          break;
        case '/claim/service/signature':
        case '/claim/service/insurancesignature':
          document.title = '授权签名';
          break;
        case '/rights':
          document.title = '元保会员';
          break;
        case '/zszq':
        case '/zszq/detail':
          document.title = '知识赚钱';
          break;
        case '/zszq/records':
          document.title = '现金明细';
          break;
        case '/zszq/withdraw':
          document.title = '提现到微信';
          break;
        case '/zszq/succ':
          document.title = '提现到成功';
          break;
        case '/simulation':
          document.title = '理赔模拟入口';
          break;
        case '/simulation/claim':
          document.title = '模拟理赔';
          break;
        case '/qiwei/memberswelfare':
        case '/qiwei/memberaequity':
          document.title = '元保，持牌保险专家';
          break;
        case '/qiwei/complaints':
        case '/qiwei/complaints/complete':
          document.title = '投诉建议';
          break;
        case '/ali/order/list':
        case '/ali/order/listh':
        case '/ali/order/detail':
          document.title = '持牌保险专家';
          break;
        default:
          // 这个白名单中的title由链路自己决定
          if (!isUrlMatchWhiteList()) {
            document.title = getTitle();
          }
          break;
      }
    }
  };

  render() {
    const { route } = this.state;
    const { path, registerOwnShare = {}, shareNotShowHome } = route || {};

    return (
      <Fragment>
        { // 非单独注册分享信息的页面 || 首信域名，默认走默认分享 shouxinOwnShare为true时走单独分享
          path && (!registerOwnShare?.yuanbao || (!registerOwnShare?.shouxin && isShouxin()))
            ? <WxShare path={path} needShowHome={!shareNotShowHome} hideShare={registerOwnShare?.hideShare} />
            : null
        }
        {renderRoutes(route ? [route] : [])}
      </Fragment>
    );
  }
}

export default withRouter(Router);
