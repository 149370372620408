/*
 * @Author: shixiaoxia
 * @Date: 2024-05-21 15:36:21
 * @LastEditors: chenqiusheng cqs_travis@163.com
 * @LastEditTime: 2025-02-05 15:27:30
 * @Description: gift/middle数据获取
 */
import { CommonHeader } from '../../../../fetch/base';
import { MallQueryDetailReq } from '../../../../fetch/mallGateway';
import { giftDetailUnLoginConfig2Service } from './config2';
import { GiftDetailUnLoginServiceResult } from './types';
import { CommonParams } from '../../../../types';

interface AbtestParams {
  productAbb: string; // 产品简称
  isWifi: number; // 1 是: 2 否
  previewCode?: string; // 预览code
  ybPosition?: string; // position
  landingPageVersion?: number; // 落地页版本
  lastVisitTime: string; // 上次访问时间
}

// 赠险请求参数
export interface UnLoginGiftDetailParam {
  queryDetailParams: MallQueryDetailReq; // 请求产品详情
  abtestParams: AbtestParams;
}

// gift/middle页整体返回结果
export const giftDetailUnLoginService = async (
  giftParam: UnLoginGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailUnLoginServiceResult> => {
  // 获取新配置赠险链路数据
  const giftDataResult = await giftDetailUnLoginConfig2Service(giftParam, commonHeader);
  return {
    ...giftDataResult,
  };
};
