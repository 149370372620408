/*
 * @Author: shixiaoxia
 * @Date: 2022-10-24 14:23:14
 * @LastEditors: cuilinchao
 * @LastEditTime: 2025-02-08 18:17:29
 * @Description: 公司类处理方法
 */
import stores from 'store';
import { device } from 'fe-yb-tools';
import { getEnv } from 'ybcommon/envUtil';
import { TENANTCODE } from './constants';

const { isWechat, isWeMini, isJingjiApp, isShouxinApp } = device;

const timeNow = Date.now();

export const HOSTMAP = {
  'yuanbaobaoxian.cn': {
    link: 'official', // 使用链路 official-官网 promote-推广 marketing-运营 pay-支付
    company: 'yuanbao', // 主体 yuanbao-经纪 shouxin-首信 shuke-数科 kechuang-科创
    companyCode: 'yb_jingji',
    companyName: '元保保险经纪（北京）有限公司', // 用于版权信息
    icpText: '京ICP备2020036643号-1', // icp信息
    relatedHost: 'shouxinbaoxian.com', // 关联域名
    appCode: 'wx_gzh_jingji', // 微信内appCode
    jiyanLocalAppid: __PUB__ ? 'a50e6c73273571aa1b7fcbcd1cfb9ba5' : '7e47dc6c04de44f0962d135891fa25ff', // 极验本机校验appid
    jiyanOneKeyAppid: __PUB__ ? '6aa28075e805880d19ace4eda139b3d8' : '7777de789b8953320bccc54774db45e8', // 极验一键登录appid
    needSyncCookie: true, // 是否需要同步登录态
  },
  'ybaomall.com': {
    link: 'promote',
    company: 'yuanbao',
    companyCode: 'yb_jingji',
    companyName: '元保保险经纪（北京）有限公司',
    icpText: '京ICP备2020036643号-2',
    relatedHost: 'sxbpa.com',
    appCode: __PUB__ ? 'wx_gzh_yjy' : 'wx_gzh_jingji',
    jiyanLocalAppid: __PUB__ ? '53b0ea574ffca55da271562527fd8bde' : 'b03755452e95aece3c5740a4cfa732de',
    jiyanOneKeyAppid: __PUB__ ? '5d6f5650f950e7b79141133b962e30a2' : 'dce4d7f20c2b1114260bc11af803aad0',
    needSyncCookie: true,
  },
  'ybaomallyy.com': {
    link: 'marketing',
    company: 'yuanbao',
    companyCode: 'yb_jingji',
    companyName: '元保保险经纪（北京）有限公司',
    icpText: '京ICP备2020036643号-24',
    relatedHost: 'sxbpayy.com',
    appCode: __PUB__ ? 'wx_gzh_yjy' : 'wx_gzh_jingji',
    jiyanLocalAppid: __PUB__ ? '53b0ea574ffca55da271562527fd8bde' : 'b03755452e95aece3c5740a4cfa732de',
    jiyanOneKeyAppid: __PUB__ ? '5d6f5650f950e7b79141133b962e30a2' : 'dce4d7f20c2b1114260bc11af803aad0',
    needSyncCookie: false,
  },
  'shouxinbaoxian.com': {
    link: 'official',
    company: 'shouxin',
    companyCode: 'sx_daili',
    companyName: '首信保险代理（广东）有限公司',
    icpText: '粤ICP备16069690号-2',
    relatedHost: 'yuanbaobaoxian.cn',
    appCode: 'wx_gzh_sx',
    jiyanLocalAppid: __PUB__ ? '25a4e3fff86381df84bf70864e72243f' : 'fa6d32d2f3a2dc0fe4e32f6817a89a92',
    jiyanOneKeyAppid: __PUB__ ? '14c9c73027715ef7378cd53dea7d6cbc' : 'bee94b5e03c6c3905978463c6c6b4702',
    needSyncCookie: true,
  },
  'sxbpa.com': {
    link: 'promote',
    company: 'shouxin',
    companyCode: 'sx_daili',
    companyName: '首信保险代理（广东）有限公司',
    icpText: '粤ICP备16069690号-12',
    relatedHost: 'ybaomall.com',
    appCode: __PUB__ ? 'wx_gzh_ybyb' : 'wx_gzh_sx',
    jiyanLocalAppid: __PUB__ ? 'f2c55870613930a6d4937d05ffbca9a2' : '8d585896be2ff00984c1e0c3102d034e',
    jiyanOneKeyAppid: __PUB__ ? 'c4d16f20c98b2e0e4f931aa8f3b338c7' : '40072013212831d0ac98be4afa8a0552',
    needSyncCookie: true,
  },
  'sxbpayy.com': {
    link: 'marketing',
    company: 'shouxin',
    companyCode: 'sx_daili',
    companyName: '首信保险代理（广东）有限公司',
    icpText: '粤ICP备16069690号-14',
    relatedHost: 'ybaomallyy.com',
    appCode: __PUB__ ? 'wx_gzh_ybyb' : 'wx_gzh_sx',
    jiyanLocalAppid: __PUB__ ? 'f2c55870613930a6d4937d05ffbca9a2' : '8d585896be2ff00984c1e0c3102d034e',
    jiyanOneKeyAppid: __PUB__ ? 'c4d16f20c98b2e0e4f931aa8f3b338c7' : '40072013212831d0ac98be4afa8a0552',
    needSyncCookie: false,
  },
  'ybbxpay.com': {
    link: 'pay',
    company: '',
    appCode: 'wx_gzh_flg',
    companyCode: 'yb_jingji',
    needSyncCookie: false,
  },
  'ybaobx.com': {
    company: 'shuke',
    companyCode: 'yb_shuke',
    companyName: '元保数科（北京）科技有限公司',
    icpText: '京ICP备19057240号-3',
    needSyncCookie: false,
  },
  'yuanbaokc.com': {
    company: 'kechuang',
    companyCode: 'yb_jingji',
    needSyncCookie: false,
  },
};

/**
 * @name: 获取二级域名（域名枚举中的key：xxx.cn/com）
 * @param {*} host 指定的域名
 * @return {*}
 */
export const getSubDomain = (host = '') => {
  // if (!host && __DEV__) return 'shouxinbaoxian.com';
  const _host = host || window.location.host;
  const hostArr = _host.split('.');
  const subDomain = hostArr?.length >= 2 ? `${hostArr[hostArr.length - 2]}.${hostArr[hostArr.length - 1]}` : '';
  return subDomain;
};

// 是否首信域名
export const isShouxin = (host = '') => {
  // if (__DEV__) return true;
  const subDomain = getSubDomain(host);
  const _result = HOSTMAP[subDomain]?.company?.indexOf('shouxin') > -1;
  return _result;
};

// 判断当前域名是否是元保经纪域名
export const isCN = (host = '') => {
  // if (__DEV__) return true;
  const subDomain = getSubDomain(host);
  const _result = HOSTMAP[subDomain]?.company?.indexOf('yuanbao') > -1;
  return _result;
};

// 是否数科域名
export const isYB = (host = '') => {
  const subDomain = getSubDomain(host);
  const _result = HOSTMAP[subDomain]?.company?.indexOf('shuke') > -1;
  return _result;
};

// 是否科创域名
export const isKc = (host = '') => {
  const subDomain = getSubDomain(host);
  const _result = HOSTMAP[subDomain]?.company?.indexOf('kechuang') > -1;
  return _result;
};

/**
 * 获取公众号名称
 * @returns 公众号名称
 */
export const getGZHName = () => {
  let gzhName = '元保健康';
  if (isCN()) {
    gzhName = '元保';
  } else if (isShouxin()) {
    gzhName = '首信';
  }
  return gzhName;
};

/**
 * 获取tab数据
 * @returns tabData tab数据
 */
export const getTabData = () => {
  let tabData = [{
    title: '首页',
    link: '/',
    icon: 'iconshouye1',
    iconselect: 'iconshouye-xuanzhong1',
    active: true,
  },
  {
    title: '产品',
    link: '/cargo/index',
    icon: 'iconchanpin1',
    iconselect: 'iconchanpin-xuanzhong',
    active: true,
  },
  {
    title: '攻略',
    link: '/schoolroom/detail',
    icon: 'iconbaoxiangonglveh5',
    iconselect: 'iconbaoxiangonglveh5_02',
    active: false,
  },
  {
    title: '我的',
    link: '/my',
    icon: 'iconwode',
    iconselect: 'iconwode-xuanzhong1',
    active: false,
  }];
  if (isShouxin()) {
    tabData = [{
      title: '首页',
      link: '/',
      icon: 'iconicon_home_line',
      iconselect: 'iconicon_home',
      active: true,
    }, {
      title: '产品',
      link: '/cargo/index',
      icon: 'iconicon_cb_unchoose_line',
      iconselect: 'iconicon_cb_unchoose',
      active: false,
    }, {
      title: '我的',
      link: '/my',
      icon: 'iconicon_my_line',
      iconselect: 'iconicon_my',
      active: false,
    }];
  }
  return tabData;
};

/**
 * @name: 从二维对象中查找指定key为指定值的对象的key
 * @param {*} obbj 要查找的二维对象
 * @param {*} findKey 要查找的字段key
 * @param {*} value 要查找的字段的值
 * @return {*}
 */
const findKeyInObject = (obj, findKey, value) => {
  for (const key in obj) {
    if (obj[key][findKey] === value) {
      return key;
    }
  }
  return '';
};

/**
 * @name: 根据当前环境获取域名前缀
 * @return {*}
 */
export const getHostPre = () => {
  const env = getEnv();
  let hostPre = 'exchange';
  switch (env) {
    case 'pre':
      hostPre = 'exchange-pre';
      break;
    case 'online':
      break;
    default:
      hostPre = `${env}exchange`;
      break;
  }
  return hostPre;
};

/**
 * @name: 根据当前/指定域名获取各主体域名
 * @param {*} host 指定域名（必须为经纪/首信域名）
 * @return {*}
 */
export const getHost = (host = '') => {
  const hostPre = getHostPre();
  const subDomain = getSubDomain(host);
  const cnhost = isCN(host) ? `${hostPre}.${subDomain}` : `${hostPre}.${HOSTMAP[subDomain]?.relatedHost}`;
  const sxhost = isShouxin(host) ? `${hostPre}.${subDomain}` : `${hostPre}.${HOSTMAP[subDomain]?.relatedHost}`;
  const ybaobxhost = `${hostPre}.${findKeyInObject(HOSTMAP, 'company', 'shuke')}`;
  const payhost = `${hostPre}.${findKeyInObject(HOSTMAP, 'link', 'pay')}`;

  return { cnhost, ybaobxhost, sxhost, payhost };
};

/**
 * @name: 获取指定主体和链路下的域名
 * @param {*} company 主体 yuanbao-经纪 shouxin-首信
 * @param {*} link 链路 official-官网 promote-推广 marketing-运营 pay-支付
 * @return {*}
 */
export const getHostByLink = (company, link) => {
  let host = '';
  let origin = '';
  for (const key in HOSTMAP) {
    // 取对应主体的营销域名
    if (HOSTMAP[key].link === link && HOSTMAP[key].company === company) {
      host = key;
      break;
    }
  }
  if (host) origin = `${window.location.protocol}//${getHostPre()}.${host}`;

  return { host, origin };
};

/**
 * 获取当前环境的appCode 用户中心接口使用
 * @returns appCode 跟环境、域名相关
 */
export const getAppCode = (isWeChatMiniAppValue, host) => {
  const isWeChatMiniApp = isWeChatMiniAppValue || stores?.userStore?.isWeChatMiniApp;
  let appCode = isShouxin() ? 'h5_app_sx' : 'h5_app_web'; // h5
  if (isWechat() && !isWeMini()) {
    const subDoamin = getSubDomain(host);
    appCode = HOSTMAP[subDoamin]?.appCode;
  } else if (+isWeChatMiniApp === 1) {
    appCode = 'wx_miniapp_mall'; // 微信经纪小程序
  } else if (+isWeChatMiniApp === 5) {
    appCode = 'wx_miniapp_sx'; // 微信首信小程序
  } else if (+isWeChatMiniApp === 6) { // 数科支付宝
    appCode = 'zfb_miniapp_skts';
  } else if (+isWeChatMiniApp === 7) { // 数科支付宝-退款助手
    appCode = 'zfb_miniapp_tkzs';
  } if (+isWeChatMiniApp === 8) { // 数科支付宝-退款售后
    appCode = 'zfb_miniapp_tksh';
  } else if (+isWeChatMiniApp === 9) { // 抖音经纪小程序
    appCode = 'dy_miniapp_mall';
  } else if (+isWeChatMiniApp === 10) { // 抖音首信小程序
    appCode = 'dy_miniapp_ybyx';
  }
  return appCode;
};

/**
 * 获取主体名称
 * entity = {
 isJingJi: bool,
 isShouXin: bool,
 }
 * @returns 主体名称
 */
export const getCompanyName = (entity) => {
  if (entity) {
    if (entity.isJingJi) {
      return '元保';
    }
    if (entity.isShouXin) {
      return '首信';
    }
  }
  let gzhName = '元保';
  if (isShouxin()) {
    gzhName = '';
  }
  return gzhName;
};

/**
 * 获取咨询电话
 * @returns 咨询电话
 */
export const getTelphone = () => {
  let phone = '4006050808';
  if (isCN()) {
    phone = '4006050860';
  } else if (isShouxin()) {
    phone = '4006160170';
  }
  return phone;
};

/**
 *  获取title
 * @param {*} [isSpecial] 是否是特殊页面默认展示（该字段已弃用，产品已统一）
 * @returns 页面默认标题
 */
export const getTitle = (isSpecial) => {
  let title = isSpecial ? '元保，保险不难选' : '元保，持牌保险专家';
  if (window.location.href.indexOf('/sxcx/result') > -1) {
    // 亿学、橡树保结果页
    title = '投保结果页';
  } else if (window.location.href.indexOf('/sxcx') > -1 || window.location.href.indexOf('/cargo/coopdetail') > -1) {
    // 亿学、橡树保落地页
    title = '产品详情页';
  } else if (window.location.href.indexOf('/pay/transition') > -1 && window.location.href.indexOf('noStandardPayType=1') > -1) {
    // 第三方api下单产品过渡页（亿学蓝医保）
    title = '支付';
  } else if (window.location.href.indexOf('/order/unpaid') > -1 && window.location.href.indexOf('noStandardPayType=1') > -1) {
    // 第三方api下单产品待支付页（亿学蓝医保）
    title = '待支付页';
  } else if (isShouxin()) {
    title = isSpecial ? '首信，保障健康生活' : '首信，持牌保险专家';
  } else if (window.location.href.indexOf('=220809155229898BI60TEST') > -1 || window.location.href.indexOf('=220809162646829BI67TEST') > -1
    || window.location.href.indexOf('=230529153412310BI792736') > -1 || window.location.href.indexOf('=230529153528779BI127521') > -1) {
    title = '百万医疗险2023';
  }
  return title;
};

/**
 * 获取登录协议
 * @params changeKey 是否需要修改protocol的字段名为key/value 1-需要
 * @params userEntity 用户登录主体
 * @returns protocols 登录协议
 */
export const getLoginProtocols = (changeKey, userEntity) => {
  const { protocolPage } = stores.userStore || {};
  if (!protocolPage) {
    return [];
  }

  /* userEntity */
  /* 1 经济 2 首信 3 橡树保  */

  let protocols;
  if (userEntity) {
    // 传入用户主体
    protocols = userEntity === 1 ? protocolPage.LoginCN : protocolPage.LoginYBBX;
    if (userEntity === 2) {
      protocols = protocolPage.LoginSX;
    }
  } else {
    protocols = isCN() ? protocolPage.LoginCN : protocolPage.LoginYBBX;
    if (isShouxin()) {
      protocols = protocolPage.LoginSX;
    }
  }

  if (isJingjiApp() || isShouxinApp()) {
    // app 内打开不展示保单协议
    protocols = protocols.filter((item) => item.title !== '保单管理服务协议');
  }

  if (changeKey) {
    return protocols && protocols.map((item) => ({ key: item.title, value: item.protocolId }));
  }
  return protocols;
};

/**
 * 获取通用协议
 * @params changeKey 是否需要修改protocol的字段名为key/value 1-需要
 * @params {{isJingJi: boolean; isShouXin: boolean;}} entityInfo
 * @returns protocols 获取通用协议
 */
export const getProtocols = (changeKey, entityInfo) => {
  const { protocolPage } = stores.userStore || {};
  if (!protocolPage) {
    return [];
  }
  let protocols = (isCN() || __DEV__) ? protocolPage.CN : protocolPage.YBBX;
  if (isShouxin()) {
    protocols = protocolPage.SX;
  }
  // 如果传递了产品主体则优先以产品主体为主取值
  if (entityInfo) {
    if (entityInfo.isJingJi) {
      protocols = protocolPage.CN;
    } else if (entityInfo.isShouXin) {
      protocols = protocolPage.SX;
    }
  }

  if (changeKey) {
    return protocols && protocols.map((item) => ({ key: item.title, value: item.protocolId }));
  }
  return protocols;
};

// 根据域名判断主体编码
export const getBusinessOrigin = () => {
  const subDomain = getSubDomain();
  const companyCode = HOSTMAP[subDomain]?.companyCode || 'yb_jingji';
  return companyCode;
};

// 公司配置
export const companyConfig = {
  // 经纪公司简称
  jingJiCompanyName: '元保经纪',
  // 经纪公司全程
  jingJiCompanyFullName: '元保保险经纪（北京）有限公司',
  // 首信公司简称
  shouXinCompanyName: '首信代理',
  // 首信公司全称
  shouXinCompanyFullName: '首信保险代理（广东）有限公司',
  // 首信logo url
  shouXinLogoUrl: 'https://cdn.yuanbaobaoxian.com/ybassets/shouXinLogo_v3.png',
  // 非首信logo url
  notShouXinLogoUrl: 'https://cdn.yuanbaobaoxian.com/ybassets/YBLogo.png',
  // 代理后续文字
  suffixAgent: '提供中介服务',
};

/**
   * 获取客户告知书及对应提示文案
   * notification：首信 notification2：元保
   * isComplianceName 如果是合规名称要求, 则走合规名称逻辑
   * @returns noti-客户告知书协议Id txt-提示文案
   */
export const getNotification = (isComplianceName = false) => {
  const { notification = {}, notification2 = {} } = stores.userStore || {};
  let noti;
  let agent;

  if (isCN()) {
    noti = notification2;
    agent = isComplianceName ? companyConfig.jingJiCompanyFullName : companyConfig.jingJiCompanyName;
  } else {
    noti = notification;
    agent = isComplianceName ? companyConfig.shouXinCompanyFullName : companyConfig.shouXinCompanyName;
  }
  const txt = `${agent}${companyConfig.suffixAgent}`;

  const selfLogo = isShouxin() ? `${companyConfig.shouXinLogoUrl}?t=${timeNow}` : companyConfig.notShouXinLogoUrl;

  return { noti, txt, selfLogo, agent };
};

// 根据tenantCode判断产品主体
export const checkProductEntityByTenantCode = (tenantCode) => {
  const isJingJi = TENANTCODE.yuanbao === tenantCode;
  const isShouXin = TENANTCODE.shouxin === tenantCode;
  return { isJingJi, isShouXin };
};
