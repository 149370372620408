/**
 * 用户登录弹窗
 */
import React, { Fragment } from 'react';
import { Toast } from '@yb/fe-component';
import { CommonUtil } from 'fe-yb-tools';
import { getImgPath, debounce } from 'ybcommon/ybutils/common/common';
import { getCompanyName, getLoginProtocols } from 'ybcommon/ybutils/common/company';
import { FACEID_INFO } from 'ybcommon/ybutils/common/constants';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { storage } from 'ybcommon/ybutils';
import { postJiYanLoginAsync, preJiYanLoginAsync, doLogin, jiYanLogin, faceLogin } from 'store/LoginTools';
import stores from 'store';
import { SmsCountDown } from 'component';
import { isAndroid, isIOS } from 'utils/device';
import classnames from 'classnames';
import { checkProductEntity, getProductEntity, ifCarryUserEntityInLoginApi } from 'src/common/utils/common/entity';
import InputItem from '../InputItem';
import styles from './index.less';

const iconSelect = require('./icon_select.png');
const iconCircle = require('./icon_circle.png');

export default class Login extends React.Component {
  static defaultProps = {
    autoLogin: true, // 是否自动登录
    acceptProtocolVersion: 0, // 协议同意交互版本
    isJiyan: false, // 是否极验登录
    hideSxCompanyName: false, // 是否展示首信的主体名称，默认不展示
  };

  constructor(props) {
    super(props);
    const { version, isJiyan, acceptProtocolVersion, hideSxCompanyName } = props;
    // 获取当前产品主体，显示不同的协议
    const productEntity = getProductEntity();
    const flag = ifCarryUserEntityInLoginApi();
    this.protocols = getLoginProtocols(false, flag ? productEntity : '');
    // hideSxCompanyName，title上不展示主体名称
    this.companyName = hideSxCompanyName ? '' : getCompanyName(flag ? checkProductEntity() : null);
    this.state = {
      phone: '', // 输入的手机号
      encodePhone: '', // 脱敏后的手机号
      smsCode: '', // 验证码
      phoneReadOnly: false, // 手机号是否可更改
      showSmsInput: !isJiyan && +version !== 2 && !acceptProtocolVersion, // 是否展示验证码输入框
      isAcceptProtocal: false, // 是否同意登录协议
      isShowModal: false, // 同意协议弹窗
      isShowProtocol: false, // 是否展示协议
      showErrorMsg: '', // 错误提示
      showHand: false, // 展示小手
      modalBgLoad: false, // 协议弹窗背景图是否加载完成
      showFaceLogin: false, // 是否展示人脸识别
    };
    this._resetReportInputData();
    const pathName = window.__pathname || '';
    this.isMy = pathName.indexOf('/my') > -1;
    // this.isTriger = pathName.indexOf('/triger/detail') > -1;
    // this.isRisktest = pathName.indexOf('/risktest/result') > -1;
    this.isWenJuan = pathName.indexOf('/wenjuan') > -1;
    this.isWheelDisc = pathName.indexOf('/wheelDisc') > -1 || pathName.indexOf('/lucky') > -1;
    this.hasJiyanFail = false; // 是否请求极验失败
    this.isJiyanLogining = false; // 是否正在请求极验
  }

  // 获取子类state状态
  // 继承时用
  // return { phone, phoneReadOnly }
  _getChildState = () => null;

  componentDidMount() {
    trackEvent('h5_all_authorize_popup');
    const tmp = this._getChildState();
    if (tmp) {
      const { phone, phoneReadOnly } = tmp;
      let encodePhone = '';
      if (phoneReadOnly && phone) {
        encodePhone = CommonUtil.hideCode(phone, 0, 2); // 手机号脱敏规则改为 除最后两位其他脱敏
      }
      this.setState({ phone, phoneReadOnly: phoneReadOnly && phone, encodePhone });
    }
    const showFaceLogin = this._checkCanUseFaceID();
    if (showFaceLogin) {
      this.setState({
        showFaceLogin,
      });
    }
    setTimeout(() => {
      this._phoneInput?.focus?.();
    }, 50);
  }

  componentWillUnmount() {
    // 兜底
    this.removeEventScroll();
    // 解决登录弹窗关闭时偶现软键盘未关闭问题
    document.activeElement?.blur?.();
  }

  _resetReportInputData = () => {
    this.hasReportInput = {
      phone: false,
      sms: false,
    };
  };

  stopMove = (e) => {
    e.preventDefault();
  };

  // 解决ios软键盘弹起时蒙层下页面可滑动问题
  addEventScroll = () => {
    if (!isIOS()) return; // 仅ios
    document.addEventListener('touchmove', this.stopMove, {
      passive: false,
    });
  };

  removeEventScroll = () => {
    if (!isIOS()) return;
    document.removeEventListener('touchmove', this.stopMove);
  };

  _onBlur = (type) => {
    this.hasReportInput[type] = false;
    const { phone } = this.state;
    if (!phone && type === 'phone') {
      this.setState({ showHand: true });
    }
  };

  // 校验手机号格式是否正确
  _checkPhoneValid = (tmpPhone, noMsg) => {
    const { phone } = this.state;
    if (!tmpPhone) {
      tmpPhone = phone;
    }
    const { version } = this.props;
    const { isEmpty, isValid } = CommonUtil._checkValue(tmpPhone, 'phone');
    if (isEmpty || !isValid) {
      if (+version === 2 && !noMsg) {
        this._phoneInput?.focus?.();
        this.setState({ showErrorMsg: isEmpty ? '请输入手机号' : '请输入正确的手机号' });
      } else if (!noMsg) Toast.info('请输入正确的手机号！');
      return false;
    }
    return true;
  };

  _phoneChange = (e) => {
    const { autoLogin, isJiyan } = this.props;
    const { showSmsInput, showErrorMsg } = this.state;
    if (!this.hasReportInput.phone) {
      trackEvent(`input_data_yemian_zaidenglutanchuangshurushoujihao${this.isMy ? '_my' : ''}`, { url: window.location.href });
      this.hasReportInput.phone = true;
    }
    const _tmp = e.replace(/\s*/g, '');
    const isRightPhone = this._checkPhoneValid(_tmp, true);

    this.setState({
      phone: e,
      showErrorMsg: isRightPhone ? '' : showErrorMsg,
      showHand: false,
    }, () => {
      if (isJiyan && _tmp.length === 11 && !this.hasJiyanFail) {
        this._jiYanLogin();
        return;
      }
      if (isRightPhone) {
        const cb = () => {
          if (this.SmsCountDownRef) {
            this.SmsCountDownRef.setSmsEnabled(true, autoLogin, this.hasJiyanFail);
          }
        };
        if (showSmsInput) {
          cb();
        } else {
          this.isJiyanProtocol = false;
          this._showProtocolModal(() => {
            this.setState({ showSmsInput: true }, cb);
          });
        }
      }
    });
  };

  _showProtocolModal = (_acceptCB, _unacceptCB) => {
    const { isAcceptProtocal, showSmsInput } = this.state;
    const { acceptProtocolVersion, isJiyan, version } = this.props;
    if (!isJiyan && +acceptProtocolVersion === 2 && +version === 1 && !showSmsInput) {
      // 非极验且交互版本为2且登录弹窗为原版
      // 且没有展示验证码输入框
      // 这种情况下，先展示验证码输入框，后面登录时再弹协议弹窗
      this.modalCB = null;
      this.modalRejectCB = null;
      _acceptCB?.();
      return;
    }

    if (!isAcceptProtocal) {
      this.modalCB = _acceptCB;
      this.modalRejectCB = _unacceptCB;
      trackEvent('h5_all_loginagreementpop');
      document.activeElement?.blur?.();
      this.setState({ isShowModal: true });
    } else {
      this.modalCB = null;
      this.modalRejectCB = null;
      _acceptCB?.();
    }
  };

  _smsCodeChange = (e) => {
    if (!this.hasReportInput.sms) {
      trackEvent(`input_data_yemian_zaidenglutanchuangshuruduanxinyanzhengma${this.isMy ? '_my' : ''}`, { url: window.location.href });
      this.hasReportInput.sms = true;
    }
    const { autoLogin, isJiyan } = this.props;
    const { showErrorMsg } = this.state;
    const isRightSms = e.length === 4;
    this.setState({
      smsCode: e,
      showErrorMsg: isRightSms ? '' : showErrorMsg,
    }, () => {
      // 自动登录
      if ((isJiyan || autoLogin) && isRightSms) this._doLogin();
    });
  };

  _sendVerifyCode = () => {
    trackEvent(`click_data_yemian_zaidenglutanchuanghuoquduanxinyanzhengmaanniu${this.isMy ? '_my' : ''}`, { url: window.location.href });
    const { phone } = this.state;
    const _tmp = phone.replace(/\s*/g, '');
    if (!this._checkPhoneValid()) return;
    if (this._smsInput) this._smsInput.focus();
    // eslint-disable-next-line consistent-return
    return _tmp;
  };

  // 同意协议
  _handleAcceptProtocal = () => {
    const { isAcceptProtocal } = this.state;
    this.setState({ isAcceptProtocal: !isAcceptProtocal });
  };

  _doLogin = () => {
    // 极验请求过程中 点击登录按钮不做登录操作
    if (this.isJiyanLogining) return;
    const { isJiyan } = this.props;
    if (isJiyan && !this.hasJiyanFail) {
      this._jiYanLogin();
      return;
    }
    const { phone, smsCode, showSmsInput } = this.state;
    const { autoLogin, version } = this.props;
    const _tmp = phone.replace(/\s*/g, '');
    trackEvent('h5_all_authorize_popup_loginclick');
    if (!this._checkPhoneValid()) return;
    const _callback = () => {
      const { isEmpty, isValid } = CommonUtil._checkValue(smsCode, 'smsCode');
      if (isEmpty || !isValid) {
        if (+version === 2) {
          this._smsInput?.focus?.();
          this.setState({ showErrorMsg: smsCode ? '请输入正确的验证码' : '请输入验证码' });
        } else {
          Toast.info('请输入验证码！');
        }
        return;
      }
      doLogin(
        _tmp,
        smsCode,
        (resPhone, isRegiste) => {
          trackEvent('h5_all_authorize_popup_loginsuccess', { loginway: 'normal' });
          const { onLoginSuccess } = this.props;
          if (onLoginSuccess) onLoginSuccess(resPhone, isRegiste);
        },
        (err) => {
          if (version === 2) {
            this._smsInput?.focus?.();
            this.setState({ showErrorMsg: '验证码错误或已过期' });
          } else {
            Toast.fail(err);
          }
          trackEvent('h5_all_authorize_popup_loginfail', { reason: err?.msg });
          const { onLoginFail } = this.props;
          if (onLoginFail) { onLoginFail(err); }
        },
      );
    };
    this.isJiyanProtocol = false;
    this._showProtocolModal(showSmsInput ? _callback : () => {
      this.setState({ showSmsInput: true }, () => {
        if (this.SmsCountDownRef) {
          this.SmsCountDownRef.setSmsEnabled(true, autoLogin, this.hasJiyanFail);
        }
      });
    });
  };

  /**
   * @description: 人脸识别登录
   * @return {*}
   */
  _doFaceLogin = debounce(() => {
    const { showSmsInput } = this.state;
    const { autoLogin } = this.props;
    const _callback = () => {
      const faceInfo = this._getLocalFaceInfo();
      trackEvent('loginpopup_facelogin_click');
      faceLogin(
        faceInfo?.userId,
        (resPhone, isRegiste) => {
          const { onLoginSuccess } = this.props;
          if (onLoginSuccess) {
            trackEvent('loginpopup_faceloginresult_click', { isSuccess: 1 });
            onLoginSuccess(resPhone, isRegiste);
          }
        },
        (err) => {
          trackEvent('loginpopup_faceloginresult_click', { isSuccess: 0 });
          Toast.fail(err);
          const { onLoginFail } = this.props;
          if (onLoginFail) { onLoginFail(err); }
        },
      );
    };
    this._showProtocolModal(showSmsInput ? _callback : () => {
      this.setState({ showSmsInput: true }, () => {
        if (this.SmsCountDownRef) {
          this.SmsCountDownRef.setSmsEnabled(true, autoLogin, this.hasJiyanFail);
        }
      });
    });
  }, 500);

  // 极验登录
  _jiYanLogin = () => {
    const { isJiyan, acceptProtocolVersion } = this.props;
    if (isJiyan && acceptProtocolVersion === 2) {
      this._jiYanLoginV2Async();
      return;
    }
    trackEvent('h5_all_authorize_popup_loginclick');
    const { phone } = this.state;
    if (!this._checkPhoneValid()) return;
    const _callback = () => {
      this.isJiyanLogining = true;
      jiYanLogin(
        phone.replace(/\s*/g, ''),
        (_phone) => {
          this.isJiyanLogining = false;
          trackEvent('h5_all_authorize_popup_loginsuccess', { loginway: 'jiyan' });
          const { onLoginSuccess } = this.props;
          if (onLoginSuccess) onLoginSuccess(_phone);
        },
        () => {
          this.isJiyanLogining = false;
          this.hasJiyanFail = true;
          // 极验登录失败，展示验证码框，获取验证码
          this.setState({ showSmsInput: true }, () => {
            if (this.SmsCountDownRef) this.SmsCountDownRef._handClick();
          });
        });
    };
    this.isJiyanProtocol = true;
    this._showProtocolModal(_callback);
  };

  // delay accept protocol modal login logic
  _jiYanLoginV2Async = async () => {
    const { phone } = this.state;
    if (!this._checkPhoneValid()) return;
    const processFail = () => {
      this.isJiyanLogining = false;
      this.hasJiyanFail = true;
      // 极验登录失败，展示验证码框，获取验证码
      this.setState({ showSmsInput: true }, () => {
        if (this.SmsCountDownRef) this.SmsCountDownRef._handClick();
      });
    };
    this.isJiyanLogining = true;
    const preRes = await preJiYanLoginAsync(phone.replace(/\s*/g, ''));
    this.isJiyanLogining = false;
    if (!preRes.isSuccess) {
      processFail();
      return;
    }
    this.isJiyanProtocol = true;
    const protocolAccepted = await new Promise((resolve) => {
      this._showProtocolModal(
        () => { resolve(true); },
        () => { resolve(false); },
      );
    });
    if (!protocolAccepted) return;
    const { data, jiyan1Time } = preRes;
    this.isJiyanLogining = true;
    const postRes = await postJiYanLoginAsync(data, jiyan1Time);
    this.isJiyanLogining = false;
    if (!postRes.isSuccess) {
      processFail();
      return;
    }
    trackEvent('h5_all_authorize_popup_loginsuccess', { loginway: 'jiyan' });
    const { onLoginSuccess } = this.props;
    if (onLoginSuccess) onLoginSuccess(postRes.phone);
  };

  // 协议弹窗同意
  _handleProtocolModal = () => {
    trackEvent('h5_all_loginagreementpop_agree');
    this.setState({ isShowModal: false, isAcceptProtocal: true, showSmsInput: !this.isJiyanProtocol }, this.modalCB);
  };

  // 展示弹窗
  _handleShowProtocol = (item) => {
    trackEvent('h5_all_loginagreement_click');
    this.setState({ isShowProtocol: true });
    const { toolStore } = stores;
    toolStore.showModalByType(18, {
      type: 3,
      protocalTitle: item.title || '',
      protocolId: item.protocolId,
      useYBModal: true,
      onClose: () => {
        this.setState({ isShowProtocol: false });
      },
      afterClose: () => {
        // 解决关闭后登录弹窗可滚动问题
        document.body.setAttribute('style', 'overflow: hidden;');
      },
    });
  };

  /**
   * @description: 获取缓存的人脸信息
   * @return {*}
   */
  _getLocalFaceInfo = () => {
    const faceInfoStr = storage.get(FACEID_INFO);
    let faceInfo = null;
    try {
      faceInfo = JSON.parse(faceInfoStr);
    } catch (error) {
      faceInfo = {};
    }
    return faceInfo;
  };

  /**
   * @description: 是否可以使用人脸识别
   * @return {*}
   */
  _checkCanUseFaceID = () => {
    const { canUseFaceID } = this.props;
    const faceInfo = this._getLocalFaceInfo();
    const showFaceLogin = !!faceInfo?.enableFace && canUseFaceID;
    return showFaceLogin;
  };

  // 协议渲染
  _renderProtocol = () => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <Fragment>
      {this.protocols && this.protocols.map((item, index) => (
        <Fragment key={item.protocolId}>
          <span
            className={styles.span}
            onClick={() => {
              this._handleShowProtocol(item);
            }}
          >
            &nbsp;&nbsp;{item.title}&nbsp;&nbsp;
            {index < this.protocols.length - 1 ? '|' : ''}
          </span>
        </Fragment>
      ))}
    </Fragment>
  );

  // 旧版
  _renderContent = () => {
    const { version } = this.props;
    if (+version === 2) {
      return this._renderBigSizeModal();
    }
    return this._renderOldContent();
  };

  // 版本2 大字版
  _renderBigSizeModal = () => {
    const bgImg = getImgPath(require('./v2Img/login_bg.png'));
    const { phone, smsCode, phoneReadOnly, encodePhone, showSmsInput, isAcceptProtocal, isShowModal, isShowProtocol, showErrorMsg, showHand } = this.state;
    const { cName } = this.props;
    return (
      <div style={isShowModal || isShowProtocol ? { display: 'none' } : null} className={classnames(styles.myModal, styles.loginBox2, cName)}>
        <div style={{ textAlign: 'right', width: '100%' }}>
          <img
            className={styles.closeMy}
            src={require('./icon_close.png')}
            onClick={() => {
              trackEvent('click_data_yemian_denglutanchuang_close');
              const { onCloseAction } = this.props;
              if (onCloseAction) onCloseAction();
            }}
            alt=""
          />
        </div>
        <div className={styles.myContainer} style={{ backgroundImage: `url(${bgImg})` }}>
          <div className={styles.header}>
            <p className={classnames(styles.myTitle, isAndroid() ? styles.androidTitle : null)}>登录{+stores.userStore.isWeChatMiniApp !== 8 && this.companyName}</p>
            <div className={styles.myTip}>随时查询保单状态，快速理赔</div>
          </div>
          <div className={classnames(styles.inputBox, showErrorMsg ? styles.inputError : '')}>
            <InputItem
              type="phone"
              clear
              id="input_login_phone"
              autoFocus
              ref={(ref) => {
                if (ref) this._phoneInput = ref;
              }}
              placeholder="请输入手机号"
              value={phoneReadOnly ? encodePhone : phone}
              disabled={phoneReadOnly}
              onChange={this._phoneChange}
              onFocus={() => {
                this.setState({ showHand: false });
                this.addEventScroll();
              }}
              onBlur={() => {
                this._onBlur('phone');
                this.removeEventScroll();
              }}
              className={showSmsInput ? styles.myPhone : styles.myPhone1}
            />
            {showHand ? (
              <img src={require('./v2Img/hand.png')} alt="点击" className={styles.commonHand} />
            ) : null}
            {
              showSmsInput ? (
                <div className={styles.mySms}>
                  <InputItem
                    ref={(ref) => {
                      if (ref) this._smsInput = ref;
                    }}
                    type="tel"
                    id="input_login_sms"
                    clear
                    autoAdjustHeight={true}
                    moneyKeyboardAlign="left"
                    placeholder="请输入验证码"
                    disabledKeys="."
                    maxLength={4}
                    value={smsCode}
                    onChange={this._smsCodeChange}
                    onBlur={() => {
                      this._onBlur('sms');
                      this.removeEventScroll();
                    }}
                    onFocus={() => {
                      this.addEventScroll();
                    }}
                    className={styles.myInput}
                  />
                  <SmsCountDown
                    className={styles.myCountDown}
                    disabledClass={styles.disableCountDown}
                    doJiyanLogin={this._jiYanLogin}
                    onSend={this._sendVerifyCode}
                    ref={(ref) => {
                      this.SmsCountDownRef = ref;
                    }}
                  />
                </div>
              ) : null
            }
            {
              showErrorMsg ? (
                <div className={styles.errorBox}>
                  <div className={classnames('iconfont', 'iconalert1', styles.icon)} />
                  <div className={styles.txt}>{showErrorMsg}</div>
                </div>
              ) : null
            }
          </div>
          <img className={styles.loginBtn} src={getImgPath(require('./v2Img/login_btn.png'))} alt="登录" onClick={this._doLogin} />
          <div className={classnames(styles.loginRule, styles.loginRule2)}>
            <img src={isAcceptProtocal ? iconSelect : iconCircle} className={styles.selIcon} onClick={this._handleAcceptProtocal} alt="" />
            <span>我已阅读并同意</span>
            {this._renderProtocol()}
          </div>
        </div>
      </div>
    );
  };

  // 旧版
  _renderOldContent = () => {
    const { phone, smsCode, phoneReadOnly, encodePhone, showSmsInput, isAcceptProtocal, isShowModal, isShowProtocol, showFaceLogin } = this.state;
    const { cName, version } = this.props;
    let bgImg = '';
    if (this.isWenJuan) {
      bgImg = getImgPath(require('./wj_login_bg.png'));
    } else if (version === 3) {
      bgImg = getImgPath(require('./login_bg_sticky_bottom.png'));
    } else {
      bgImg = getImgPath(require('./login_bg.png'));
    }
    return (
      <div style={isShowModal || isShowProtocol ? { display: 'none' } : null} className={`${styles.myModal} ${version === 3 ? styles.myModalStickBottom : ''} ${cName}`}>
        <div style={{ textAlign: 'right', width: '100%' }}>
          <img
            className={styles.closeMy}
            src={require('./icon_close.png')}
            onClick={() => {
              trackEvent('click_data_yemian_denglutanchuang_close');
              const { onCloseAction } = this.props;
              if (onCloseAction) onCloseAction();
            }}
            alt=""
          />
        </div>
        <div className={`${styles.myContainer} ${version === 3 ? styles.myContainerStickBottom : ''}`} style={{ backgroundImage: `url(${bgImg})` }}>
          {this.isWenJuan ? (
            <div className={styles.wenjuanH} />
          ) : (
            <Fragment>
              <p className={classnames(styles.myTitle, isAndroid() ? styles.androidTitle : null, version === 3 ? styles.stickBottomMyTitle : null)}>登录{+stores.userStore.isWeChatMiniApp !== 8 && this.companyName}</p>
              <div className={classnames(styles.myTip, version === 3 ? styles.stickBottomMyTip : null)}>随时查询保单状态，快速理赔</div>
            </Fragment>
          )}

          <div className={styles.inputBox}>
            <InputItem
              type="phone"
              clear
              id="input_login_phone"
              autoFocus
              ref={(ref) => {
                if (ref) this._phoneInput = ref;
              }}
              placeholder="请输入投保人手机号"
              value={phoneReadOnly ? encodePhone : phone}
              disabled={phoneReadOnly}
              onChange={this._phoneChange}
              onBlur={() => {
                this._onBlur('phone');
                this.removeEventScroll();
              }}
              onFocus={() => {
                this.addEventScroll();
              }}
              className={showSmsInput ? styles.myPhone : styles.myPhone1}
            />
            {
              showSmsInput ? (
                <div className={styles.mySms}>
                  <InputItem
                    ref={(ref) => {
                      if (ref) this._smsInput = ref;
                    }}
                    type="tel"
                    id="input_login_sms"
                    clear
                    autoAdjustHeight={true}
                    moneyKeyboardAlign="left"
                    placeholder="请输入验证码"
                    disabledKeys="."
                    maxLength={4}
                    value={smsCode}
                    onChange={this._smsCodeChange}
                    onBlur={() => {
                      this._onBlur('sms');
                      this.removeEventScroll();
                    }}
                    onFocus={() => {
                      this.addEventScroll();
                    }}
                    className={styles.myInput}
                  />
                  <SmsCountDown
                    className={styles.myCountDown}
                    doJiyanLogin={this._jiYanLogin}
                    onSend={this._sendVerifyCode}
                    ref={(ref) => {
                      this.SmsCountDownRef = ref;
                    }}
                  />
                </div>
              ) : null
            }
          </div>
          <div className={styles.btnLogin} onClick={this._doLogin}>
            立即登录
          </div>
          {showFaceLogin && (
            <div className={styles.faceLogin} onClick={() => this._doFaceLogin()}>
              <img className={styles.faceIcon} src={require('./v2Img/face_icon.png')} alt="" />
              <span className={styles.faceTitle}>人脸登录</span>
            </div>
          )}
          <div className={styles.loginRule}>
            <img src={isAcceptProtocal ? iconSelect : iconCircle} className={styles.selIcon} onClick={this._handleAcceptProtocal} alt="" />
            <span>我已阅读并同意</span>
            {this._renderProtocol()}
          </div>
        </div>
      </div>
    );
  };

  // 轮播活动登陆框
  _renderContent1 = () => {
    const { phone, smsCode, isShowModal, isShowProtocol, isAcceptProtocal } = this.state;
    const { cName } = this.props;
    return (
      <div style={isShowModal || isShowProtocol ? { display: 'none' } : null} className={`${styles.wheelDiscModal} ${cName}`}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              const { onCloseAction } = this.props;
              if (onCloseAction) onCloseAction();
            }}
          />
        </div>
        <img src={require('./wheelDiscBak.png')} alt="" className={styles.bak} />
        <div className={styles.wheelContainer}>
          <InputItem
            type="phone"
            id="input_wheel_login_phone"
            clear
            autoFocus
            placeholder="请输入手机号"
            value={phone}
            onChange={this._phoneChange}
            onBlur={() => {
              this._onBlur('phone');
              this.removeEventScroll();
            }}
            onFocus={() => {
              this.addEventScroll();
            }}
            className={styles.myPhone}
          />
          <div className={styles.mySms}>
            <InputItem
              ref={(ref) => {
                if (ref) this._smsInput = ref;
              }}
              type="tel"
              id="input_wheel_login_sms"
              clear
              autoAdjustHeight={true}
              moneyKeyboardAlign="left"
              placeholder="请输入验证码"
              disabledKeys="."
              maxLength={4}
              value={smsCode}
              onChange={this._smsCodeChange}
              onBlur={() => {
                this._onBlur('sms');
                this.removeEventScroll();
              }}
              onFocus={() => {
                this.addEventScroll();
              }}
              className={styles.myInput}
            />
            <SmsCountDown
              className={styles.myCountDown}
              onSend={this._sendVerifyCode}
              ref={(ref) => {
                this.SmsCountDownRef = ref;
              }}
            />
          </div>
          <div className={styles.btnLogin} onClick={this._doLogin}>
            抽取好运
          </div>
          <div className={styles.loginRule}>
            <img src={isAcceptProtocal ? iconSelect : iconCircle} className={styles.selIcon} onClick={this._handleAcceptProtocal} alt="" />
            <span>登录即表明同意</span>
            {this._renderProtocol()}
          </div>
        </div>
      </div>
    );
  };

  // 同意协议弹窗
  _renderAgreeModal = (isShowProtocol, modalBgLoad) => (
    <div style={isShowProtocol ? { display: 'none' } : null} className={classnames(styles.protocalModal, styles.protocalModal2)}>
      <img alt="背景" src={require('./v2Img/protocol_bg.png')} className={styles.protocolBg} onLoad={() => { this.setState({ modalBgLoad: true }); }} />
      {
        modalBgLoad ? (
          <Fragment>
            <p className={styles.tips}>温馨提示</p>
            <div className={styles.protocolTxt}>
              <span>我已阅读并同意</span>
              {this._renderProtocol()}
            </div>
            <div className={styles.btns}>
              <p onClick={() => {
                trackEvent('h5_all_loginagreementpop_consider');
                this.setState({ isShowModal: false, modalBgLoad: false });
                const { isJiyanProtocol } = this.state;
                if (isJiyanProtocol && this.modalRejectCB) {
                  this.modalRejectCB();
                }
              }}
              >不同意
              </p>
              <p onClick={this._handleProtocolModal}>同意</p>
            </div>
          </Fragment>
        ) : null
      }

    </div>
  );

  render() {
    const { isShowModal, isShowProtocol, modalBgLoad } = this.state;
    const content = this.isWheelDisc ? this._renderContent1 : this._renderContent;
    return (
      <Fragment>
        {content()}
        {isShowModal ? this._renderAgreeModal(isShowProtocol, modalBgLoad) : null}
      </Fragment>
    );
  }
}
