/*
 * @Author: shixiaoxia
 * @Date: 2022-10-24 14:54:15
 * @LastEditors: jiangwei
 * @LastEditTime: 2025-01-17 17:45:17
 * @Description: 基础方法
 */
import React from 'react';
import stores from 'store';
import { type as Type, md5, device } from 'fe-yb-tools';
import { Base64 } from 'js-base64';
import { LINKTYPEMAP } from 'ybcommon/ybutils/common/constants';

// export const UplogCatchErrorCode = {
//   // 接口致命错误
//   serviceFatalFail: 'serviceFatalFail',
//   // 接口一般错误
//   serviceGeneralFail: 'serviceGeneralFail',
//   // 接口成功
//   serviceSuccess: 'serviceSuccess',
//   // 业务致命错误
//   businessFatalFail: 'businessFatalFail',
//   // 业务一半错误
//   businessGeneralFail: 'businessGeneralFail',
//   // 业务成功
//   businessSuccess: 'businessSuccess',
// };

// 身份证号输入限制正则
export const CERT_NO_REG = /[^0-9A-Za-z]/g;

/**
   * @description: 替换url参数值方法
   * @param {string} oUrl 原始url
   * @param {string} paramName 替换的参数名
   * @param {string} replaceWith 替换的参数值
   * @return {string} 替换后url
   */
export const replaceParamVal = (oUrl, paramName, replaceWith) => {
  const re = new RegExp(`(${paramName}=)([^&]*)`, 'gi');
  const nUrl = oUrl.replace(re, `${paramName}=${replaceWith}`);
  return nUrl;
};

/**
* @description: 请求参数处理
* @param {object} reqParams 请求参数
* @return {string} base6后结果，即msg参数
*/
export const getFetchMsg = (reqParams) => Base64.encode(JSON.stringify(reqParams));

/**
 * @description: 获取请求签名
 * @param {string} msg msg参数
 * @return {string} 返回签名sign参数
 */
export const getFetchSign = (msg) => {
  const timezone = 8; // 目标时区时间，东八区
  const offsetGMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
  const nowDate = new Date().getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
  const targetDate = new Date(nowDate + offsetGMT * 60 * 1000 + timezone * 60 * 60 * 1000);
  targetDate.setHours(0, 0, 0, 0);
  const key = `_nsip_play_${targetDate.getTime() - (offsetGMT / 60 + 8) * 60 * 60 * 1000}`;
  return md5(msg + key);
};

/**
   * @description: 将object转化为链接上的search串
   * @param {object} 参数对象
   * @return {string} search字符串
   */
export const transferSearchObj2Str = (obj) => {
  let result = '';
  for (const item in obj) {
    if (item && (obj[item] || obj[item] === 0)) {
      result += `${item}=${obj[item]}&`;
    }
  }
  if (result.length > 0) {
    result = `?${result.substr(0, result.length - 1)}`;
  }
  return result;
};

const { isWechat, isWeMini, isAlipay, isAlipayClient, isTTMini } = device;

/**
 * 获取当前环境
 * @returns {number} source 当前来源环境
 */
export const getSource = () => {
  let source = 1; // 纯h5环境
  if (isWeMini()) {
    // 微信小程序
    source = 4;
  } else if (isWechat()) {
    // 微信内
    source = 0;
  } else if (isAlipayClient()) {
    // 支付宝小程序
    source = 3;
  } else if (isAlipay()) {
    // 支付宝
    source = 2;
  } else if (isTTMini()) {
    source = 8; // 新的抖音经纪和抖音首信小程序
  }
  return source;
};

/**
 * 开启回溯，产品落地页需要兼容从保障详情回来的情况
* interface Options {
 *   // 订单 id
 *   batchOrderId: string;
 *   // 设置链路值，性能上报时传递
 *   pzLinkType: string;
 *   // 区分页面真实内容的路径（登录页、落地页、支付半屏等）
 *   realPagePath: string;
 * }
 *
 * @param {string|object} options 如果是字符串，则认为是订单号；默认是配置对象
 */
export const startTrack = (options = {}) => {
  const ybTrack = window.yb;
  if (ybTrack) {
    ybTrack.startTrack(options);
  }
};

/**
 * 发送可回溯事件
 * 目前仅用于发送可回溯屏蔽事件，包括可回溯屏蔽开始事件和结束事件
 *
 * @param {*} eventName 事件名称 'block-start' | 'block-end'
 * @param {*} payload 事件携带的参数
 */
export const sendRecordEvent = (eventName, payload = {}) => {
  const ybTrack = window.yb;
  if (ybTrack) {
    ybTrack.sendRecordEvent(eventName, payload);
  }
};

/**
 * 更新 track 配置
 *
 * @param {object} options 配置对象，同 startTrack 的 options 参数类型
 */
export const updateTrackOptions = (options = {}) => {
  const ybTrack = window.yb;
  if (ybTrack) {
    ybTrack.updateTrackOptions(options);
  }
};

// 截流
export const throttle = (func, wait) => {
  let lastTime = null;
  let timeout;
  return function (...arg) {
    const context = {};
    const now = new Date();
    // 如果上次执行的时间和这次触发的时间大于一个执行周期，则执行
    if (now - lastTime - wait > 0) {
      // 如果之前有了定时任务则清除
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      func.apply(context, arg);
      lastTime = now;
    } else if (!timeout) {
      timeout = setTimeout(() => {
        // 改变执行上下文环境
        func.apply(context, arg);
      }, wait);
    }
  };
};

// 节流 不执行最后一次操作
export const throttleWithoutLastEex = (func, wait) => {
  let lastTime = null;
  let timeout;
  return function (...arg) {
    const context = {};
    const now = new Date();
    // 如果上次执行的时间和这次触发的时间大于一个执行周期，则执行
    if (now - lastTime - wait > 0) {
      // 如果之前有了定时任务则清除
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      func.apply(context, arg);
      lastTime = now;
    }
  };
};
/**
 * @description: 将location search转成一个obj
 * @param {string} search window.location.search
 * @param {boolean} [needDecode] 是否需要解码
 * @return {Record<string, string>} 返回参数对象，key为参数名，value为参数值
 */
export const getLocationSearch = (search, needDecode) => {
  const ret = {};
  if (search) {
    const seg = search.replace(/^\?/, '').split('&');
    for (let i = 0; i < seg.length; i++) {
      if (seg[i]) {
        const s = seg[i].replace(/=/, '&').split('&');
        ret[s[0]] = needDecode ? decodeURIComponent(s[1]) : s[1];
      }
    }
  }
  return ret;
};

/**
 * @description: 获取url参数
 * @param {string} key 参数名
 * @param {boolean} needDecode 是否需要解码
 * @param {string} search search
 * @return {any} 参数值
 */
export const getUrlParam = (key = '', needDecode = false, search = '') => {
  const ret = getLocationSearch(search || window.location.search, needDecode);
  if (key) {
    return ret[key];
  }
  return ret;
};

/**
 * 给url添加参数
 * 注意点：此函数会将params里的参数覆盖原有的参数值，如果传入的为undefined会过滤掉
 * @param {*} originUrl 原始url
 * @param {*} params 参数，可为string(格式为a=b&c=d)、object
 * @param {*} delHash 参数，true或者false，是否删除#
 * @returns {string} 处理后url
 */
export const addUrlParams = (originUrl, params = null, delHash = false) => {
  // 如果任何一个参数为空，则直接返回
  if (!(params && originUrl)) return originUrl;
  const originUrlTemp = delHash ? originUrl.split('#')[0] : originUrl;
  const searchStr = originUrlTemp.split('?')[1];
  const path = originUrlTemp.split('?')[0];
  let resultUrl = originUrlTemp;
  // 原始链接里参数
  const originParmasObj = getLocationSearch(searchStr);
  if (Type.isString(params) || Type.isObject(params)) {
    // 处理新添加的参数对象
    const addParamsObj = Type.isObject(params) ? params : getLocationSearch(params);
    // 新旧参数合并并转为search格式
    const finalSearch = transferSearchObj2Str({ ...originParmasObj, ...addParamsObj });
    // 拼接完整链接
    resultUrl = finalSearch ? `${path}${finalSearch}` : path;
  } else {
    console.info('参数错误，params只能为字符串或者对象');
  }
  return resultUrl;
};

/**
 * px转rem 宽度为375为准
 * @method
 * @param {number} value 需要转化的px值
 * @returns {number} 转化后的rem值
 */
export const pxTOrem = (value) => {
  // 375表示在postcss.config.js里配置的设计稿的宽度
  const result = (10 * value) / 375;
  return result;
};

// 防抖：
export const debounce = (fn, delay) => {
  let timer = null;
  return (...arg) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply({}, arg);
      timer = null;
    }, delay);
  };
};

/**
 * @description:
 * @param {*} bindStatus 绑卡状态
 * @param {*} cardType 1 储蓄卡 2 信用卡
 * @return {*} 0 跳转输入卡号绑卡, 1 一键绑卡
 */
export const checkJumpMethod = (bindStatus) => {
  const BINDSTATUSTYPE = {
    ONEKEYBIND: 'oneKeyBind',
    EnterCardBind: 'cardNoBind',
  };
  const keys = Object.keys(bindStatus || {});
  return keys?.includes(BINDSTATUSTYPE.ONEKEYBIND);
};

export const retry = (componentImport, retryNum) => new Promise((resolve) => {
  componentImport()
    .then(resolve)
    .catch((err) => {
      if (retryNum > 0) {
        retry(componentImport, retryNum - 1).then(resolve);
      } else if (window.yb && window.yb.uplogCatchError) {
        window.yb.uplogCatchError(JSON.stringify({ code: 'chunk_load_failed', msg: { message: err?.message || 'chunk 加载失败', stack: err?.stack ? err.stack.split(':') : '' } }));
      }
    });
});

/**
 * 懒加载组件失败重试方法
 * @param {*} componentImport 动态引入方法
 * @param {*} retryNum 重试次数，默认3次
 * @returns 组件
 */
export const lazyLoadWithRetry = (componentImport, retryNum = 3) => React.lazy(() => retry(componentImport, retryNum));

export const replaceX = (certNo) => {
  if (certNo.includes('x')) {
    certNo = certNo.replace(/x/g, 'X');
  }
  return certNo;
};

/**
 * 判断channel指定位是否包含指定字符串
 * @param {*} channel
 * @param {*} targetStr 目标字符串（忽略大小写）
 * @param {*} index channel中的第几位索引（从0开始，不传默认0）
 */
export const judgeChannel = (channel, targetStr, index = 0) => {
  if (!channel || !targetStr) return false;
  const items = channel.split('_');
  const regExp = new RegExp(`.*${targetStr}.*`, 'i');
  if (items && items.length >= index + 1 && regExp.test(items[index])) {
    return true;
  }
  return false;
};

/**
 * 根据渠道后拼接值判断是否是分流实验预览（预览时会在原channel渠道值后拼接下划线+13位时间戳）
 */
export const judgeIsShuntPreview = () => {
  const channel = getUrlParam('channel') || '';
  return /.+_\d{13}$/.test(channel);
};

/**
 * 处理图片路径，如果支持webp格式则拼接webp后缀，否则返回原路径
 * @param  path-图片源路径
 *  window.__YB_MALL_WEBP_IS_SUPPORTED__:0或者1。1为支持webp,0为不支持webp
 * @returns
 */
export const getImgPath = (path) => {
  path = (path && path.default) || path;
  if (!path || path.indexOf('x-oss-process') > -1) return path;
  const arr = ['cdn.ybaobx.com', 'cdn.yuanbaobaoxian.com'];
  let newPath = path;
  let isAllow = false;
  // base64图片不处理
  if (newPath.indexOf('data:image') > -1) {
    return newPath;
  }
  for (const item of arr) {
    if (newPath.indexOf(item) > -1) {
      isAllow = true;
    }
  }
  // 不在定义域名内就不处理
  if (!isAllow) {
    return newPath;
  }
  if (window.__YB_MALL_WEBP_IS_SUPPORTED__) {
    const signUrl = path.indexOf('?') > -1 ? '&' : '?';
    // newPath += `${signUrl}x-oss-process=image/format,webp`;
    newPath += `${signUrl}x-oss-process=style/webp`;
  }
  return newPath;
};

/**
 * 上报错误到棱镜
 * @param  err 错误信息
 * @param  code 错误类型
 */
export const uplogCatchError = (err, code = 'fetchError') => {
  if (typeof err === 'object') {
    if (__DEV__) console.error(err);
    if (window.yb && window.yb.uplogCatchError) {
      const error = {
        code,
        msg: {
          url: window.location.href,
          ...err,
        },
      };
      window.yb.uplogCatchError(JSON.stringify(error));
    }
  }
};

// /**
//  * @description 接口致命错误上报，有波动需要立即响应
//  * @param {*} code
//  * @param {*} param
//  * @param {*} result
//  */
// export function serviceFatalFailLog(message, param, result) {
//   uplogCatchError({ message, param, result }, UplogCatchErrorCode.serviceFatalFail);
// }

// /**
//  * @description 接口一般错误上报，有波动需要排查
//  * @param {*} code
//  * @param {*} param
//  * @param {*} result
//  */
// export function serviceGeneralFailLog(message, param, result) {
//   uplogCatchError({ message, param, result }, UplogCatchErrorCode.serviceGeneralFail);
// }

// /**
//  * @description 接口成功上报，有波动需要排查
//  * @param {*} code
//  * @param {*} param
//  * @param {*} result
//  */
// export function serviceSuccessLog(message, param, result) {
//   uplogCatchError({ message, param, result }, UplogCatchErrorCode.serviceSuccess);
// }

// /**
//  * @description 业务致命错误上报，有波动需要立即响应
//  * @param {*} code
//  * @param {*} param
//  * @param {*} result
//  */
// export function businessFatalFailLog(message, param, result) {
//   uplogCatchError({ message, param, result }, UplogCatchErrorCode.businessFatalFail);
// }

// /**
//  * @description 业务一般错误上报，有波动需要排查
//  * @param {*} code
//  * @param {*} param
//  * @param {*} result
//  */
// export function businessGeneralFailLog(message, param, result) {
//   uplogCatchError({ message, param, result }, UplogCatchErrorCode.businessGeneralFail);
// }

// /**
//  * @description 业务成功上报，有波动需要排查
//  * @param {*} code
//  * @param {*} param
//  * @param {*} result
//  */
// export function businessSuccessLog(message, param, result) {
//   uplogCatchError({ message, param, result }, UplogCatchErrorCode.businessSuccess);
// }

/**
 * 判断记录时间与当前时间是否超过24小时
 * @param {*} recordTime 当前时间
 */
export const checkIsMornThan24Hours = (recordTime) => {
  // recordTime 不存在表示首次进入
  if (recordTime) {
    const distance = new Date().getTime() - recordTime;
    if (distance <= 24 * 60 * 60 * 1000) return false;
    return true;
  }
  return true;
};

/**
 * 根据图片原始宽高获取像素比获取图片宽高
 * @param {*} pic 图片地址
 */
export const getImageSize = (pic = '') => {
  const search = pic.split('?');
  if (pic && search && search[1]) {
    const { size } = getLocationSearch(search[1]);
    const [height = 0, width = 0] = size.split('*');
    const ratio = Math.ceil(width / 375);

    const resultHeight = pxTOrem((+height) / ratio);
    const resultWidth = pxTOrem((+width) / ratio);
    return { width: `${resultWidth}rem`, height: `${resultHeight}rem` };
  }
  return {};
};

/**
 * @description: 自定义PromiseAllSettled函数,系统提供的会存在兼容性问题
 * @return {*}
 */
export const PromiseAllSettled = (promises) => {
  // 避免旧的浏览器出现兼容
  const rejectHandler = (reason) => ({ status: 'rejected', reason });
  const resolveHandler = (value) => ({ status: 'fulfilled', value });
  const convertedPromises = promises.map((p) => Promise.resolve(p).then(resolveHandler, rejectHandler));
  return Promise.all(convertedPromises);
};

/**
 * @name: 判断链接是否以javascript:开头
 * @param {*} url
 * @return {*}
 */
export const judgeStartWithJavascript = (url) => {
  const regExp = /^javascript:/;
  if (regExp.test(url)) {
    return true;
  }
  return false;
};

/**
 * @name: 跳转工信部网站地址（点击icp）
 * @return {*}
 */
export const jumpToIcpBeian = () => {
  window.location.href = 'https://beian.miit.gov.cn/';
};

/**
 * @name: 获取组合产品类型
 * @param {*} combineAbb 0+长组合产品简称
 * @param {*} coupleAbb 均分组合产品简称
 * @param {*} composeAbb 0+12组合产品简称
 * @return {*}
 */
export const getComLinkType = (combineAbb, coupleAbb, composeAbb) => {
  let linkType = LINKTYPEMAP.other;
  if (composeAbb) {
    linkType = LINKTYPEMAP.compose;
  } else if (coupleAbb) {
    linkType = LINKTYPEMAP.couple;
  } else if (combineAbb) {
    linkType = LINKTYPEMAP.combine;
  }
  return linkType;
};

/**
 * @name: 获取接口入参productAbb
 * @param {*} productAbb 产品简称
 * @param {*} combineAbb 0+长组合产品简称
 * @param {*} coupleAbb 均分组合产品简称
 * @param {*} composeAbb 0+12组合产品简称
 * @return {*}
 */
export const getProductAbb = (productAbb, combineAbb, coupleAbb, composeAbb) => {
  if (!productAbb) return '';
  // 组合链路上传combineAbb或coupleAbb或composeAbb，不传productAbb
  if (combineAbb || coupleAbb || composeAbb) return '';
  return productAbb;
};

// 给链接附加最新用户信息
export const addNewUserInfoToUrl = (link) => {
  const {
    userStore: { hasLogin, unionId, isWeChatMiniApp, appId },
  } = stores;
  if (hasLogin && isWeMini()) {
    // 小程序中已登录用户
    // 重定向或者刷新页面时附带userstore里面最新的用户信息
    const search = link?.split('?')[1];
    const tempParams = getLocationSearch(search);
    // tempParams.userId = userId;
    tempParams.unionId = unionId;
    // tempParams.userToken = userToken;
    tempParams.isWeChatMiniApp = isWeChatMiniApp;
    tempParams.appId = appId;

    const tempSearch = transferSearchObj2Str(tempParams);
    const href = link.split('?')[0];
    link = `${href}${tempSearch}`;
  }
  return link;
};

/**
 * @name: 从url中解析域名
 * @param {*} url
 * @return {*}
 */
export const getHostFromUrl = (url) => {
  if (!url) return {};
  let host = '';
  // 去除url上的hash和search
  const newUrl = url?.split('#')?.[0]?.split('?')?.[0] || '';
  const splitArr = newUrl?.split('/') || [];
  if (newUrl.indexOf('://') > -1) {
    // url中包含协议，取第三个
    host = splitArr[2];
  } else {
    // 无协议，取第一个
    host = splitArr[0];
  }
  // 去除端口号
  const hostname = host.split(':')[0];

  return { host, hostname };
};

// 修改页面标题前的图标
export function changeFavicon(url) {
  const faviconIcon = url || 'https://cdn.yuanbaobaoxian.com/newfaviconc.ico';
  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = faviconIcon; // 替换为新的favicon路径
  link.type = 'image/x-icon';
  document.head.appendChild(link);

  // 可选：移除旧的favicon（如果有的话）
  const oldFavicon = document.querySelector('link[rel="icon"]');
  if (oldFavicon) {
    document.head.removeChild(oldFavicon);
  }
}
