/**
 * 0808PRD | 转委托模式升级-首信&经纪用户隔离：https://yuanbaoshuke.feishu.cn/docx/K4C9dImKYonQ7uxOGzycBrufnIh
 * 0923PRD | 【AB实验】登录弹窗ab测支持0+短链路：https://yuanbaoshuke.feishu.cn/docx/ZALUdpTaooTWhExF3XZcFSnNnJg
 * 0924PRD | 0+短兼容转委托模式：https://yuanbaoshuke.feishu.cn/docx/ZK9qd8RIQo2jpGxPpvZcEWTxnwh
 * 设计文档: https://yuanbaoshuke.feishu.cn/docx/KpgvdJdqkoSXKvxGwQRcKp1vnmg
*/
import { isCN, isShouxin, getHost } from 'ybcommon/ybutils/common/company';
import { TENANTCODE } from 'ybcommon/ybutils/common/constants';
import { ENTITY_AND_PRODUCTID_MAP, Entity } from 'fe-web-common';

import userStore from 'store/UserStore';
import { userEntityAndProductEntityIsMatch as commonUserEntityAndProductEntityIsMatch } from '../../../../../../fe-web-common/services/gift/detail/login/config2';
import { handleTitleAndFavicon } from './removeYuanbao';

// 登录时始终传groupId的页面
const LOGIN_WITH_GROUPID_LIST = [
  '/resign/unwind',
];

// 路由白名单，调用bff/start接口的时候，在白名单中的路由增加ignoreCheckGroup参数，则bff/start接口会放弃对域名和主体的校验。
export const LOGIN_WHITE_LIST = [
  '/cargo/middle',
  '/gift/middle',
  '/router/detail',
  '/cargo/login',
  '/cargo/couple',
  '/gift/detail',
  '/resultNew/detail',
  '/order/unpaid',
  '/cargo/detail',
  '/cargo/compose',
  '/resign/unwind',
  '/pay/transition',
];

// 【产品主体】
let productEntity: Entity = null;
// 【用户主体】
let userEntity: Entity = null;
/**
 * 开关关闭，则userEntityAndProductEntityIsMatch永远返回true，即按照主体一致对待。
 * 涉及转委托的相关需求时，务必确认是否要受开关控制。
*/
let entitySwitch = false;

// // 商城接口ybComponentId的值和Entity的映射表
// const ENTITY_AND_PRODUCTID_MAP = {
//   yuanbao: Entity.JingJi,
//   beiJinBranch: Entity.JingJi,
//   shuke: Entity.JingJi,
//   shouxin: Entity.ShouXin,
// };

// 用户中心中gruoupId的值和Entity的映射
const ENTITY_AND_USER_MAP = {
  1: Entity.JingJi,
  2: Entity.ShouXin,
  3: Entity.XiangShuBao,
};

// 检查当前路由是否在登录时始终传groupId的页面中
function isInLoginWithGroupList() {
  /** 末尾有斜杠 */
  const PATHNAME_REG = /\/$/;
  const pathname = window.location.pathname.replace(PATHNAME_REG, '');
  return LOGIN_WITH_GROUPID_LIST.includes(pathname);
}

// 开关
export function getEntitySwitch(): boolean {
  if (__DEV__) return true;
  return entitySwitch;
}

/** 设置【开关】 */
export function setEntitySwitch(ifOpen: boolean): void {
  entitySwitch = !!ifOpen;
}

/** 获取【产品主体】 */
export function getProductEntity(): Entity {
  return productEntity;
}

/**
 * 检查是否是某个【产品主体】
 * 注意：如果没有entity参数，默认检查当前这个链路的这个产品是哪个主体（这需要依赖在这个链路中你已经调用过setProductEntity方法了）。
 *
 * @export
 * @param {?Entity} [entity]
 * @returns {{ isJingJi: boolean; isShouXin: boolean; }}
 */
export function checkProductEntity(entity?: Entity) {
  const value = entity || productEntity;
  const isJingJi = value === Entity.JingJi;
  const isShouXin = value === Entity.ShouXin;
  return { isJingJi, isShouXin };
}

/** 重置【产品主体】 */
export function resetProductEntity(): void {
  productEntity = null;
}

/** 设置【产品主体】 */
export function setProductEntity(id: keyof typeof ENTITY_AND_PRODUCTID_MAP): void {
  if (ENTITY_AND_PRODUCTID_MAP[id]) {
    productEntity = ENTITY_AND_PRODUCTID_MAP[id];
  }
}

/** 设置【产品主体】，并且做一些事 */
export function setProductEntityAndHandleTitle(id: keyof typeof ENTITY_AND_PRODUCTID_MAP): void {
  setProductEntity(id);

  // 延迟是为了避免链路中有的代码会修改document.title
  setTimeout(() => { handleTitleAndFavicon(); }, 0);
}

/** 通过GroupId设置【产品主体】 */
export function setProductEntityByGroupId(groupId: keyof typeof ENTITY_AND_USER_MAP): void {
  if (ENTITY_AND_USER_MAP[groupId]) {
    productEntity = groupId;
  }
}

/** 通过tenantCode设置【产品主体】 */
export function setProductEntityByTenantCode(tenantCode: TENANTCODE, needHandleTitle: boolean): void {
  if (tenantCode) {
    const id = TENANTCODE.shouxin === tenantCode ? 'shouxin' : 'yuanbao';
    if (needHandleTitle) {
      // 需要设置title
      setProductEntity(id);
      handleTitleAndFavicon();
    } else {
      // 仅设置产品主体
      setProductEntity(id);
    }
  }
}

/** 重置【产品主体】相关信息 */
export function resetProductEntityRelationInfo(): void {
  productEntity = null;
  if (getEntitySwitch()) {
    // 根据产品主体更新用户关注状态
    userStore.updateUserSubscribe();
  }
}
/** 设置主体&设置关注状态 */
export function setProductEntityRelationInfo(id: keyof typeof ENTITY_AND_PRODUCTID_MAP): void {
  if (ENTITY_AND_PRODUCTID_MAP[id]) {
    productEntity = ENTITY_AND_PRODUCTID_MAP[id];
    if (getEntitySwitch()) {
      // 根据产品主体更新用户关注状态
      userStore.updateUserSubscribe({
        isJingji: checkProductEntity().isJingJi,
        isShouXin: checkProductEntity().isShouXin,
      });
    }
    // 延迟是为了避免链路中有的代码会修改document.title
    setTimeout(() => { handleTitleAndFavicon(); }, 0);
  }
}

/** 获取【用户主体】 */
export function getUserEntity(): Entity {
  return userEntity;
}

/**
 * 检查是否是某个【用户主体】
 * 注意：如果没有entity参数，默认检查当前登录用户是哪个主体（这需要依赖调用过setUserEntity方法了）。
 *
 * @export
 * @param {?Entity} [entity]
 * @returns {{ isJingJi: boolean; isShouXin: boolean; }}
 */
export function checkUserEntity(entity?: Entity) {
  const value = entity || userEntity;
  const isJingJi = value === Entity.JingJi;
  const isShouXin = value === Entity.ShouXin;
  return { isJingJi, isShouXin };
}

/** 设置【用户主体】 */
export function setUserEntity(groupId: 1 | 2 | 3): void {
  if (ENTITY_AND_USER_MAP[groupId]) {
    userEntity = ENTITY_AND_USER_MAP[groupId];
  }
}

// 检查当前路由是否在登录的路由白名单中
export function isInLoginWhiteList() {
  /** 末尾有斜杠 */
  const PATHNAME_REG = /\/$/;
  const pathname = window.location.pathname.replace(PATHNAME_REG, '');
  return LOGIN_WHITE_LIST.includes(pathname);
}

/** 登录时是否携带【用户主体】给用户中心 */
export function ifCarryUserEntityInLoginApi() {
  // 当前路由在登录时始终传groupId的页面中
  if (isInLoginWithGroupList()) return true;
  // 同时满足: 开关打开——有产品主体——路由在白名单中
  return getEntitySwitch() && getProductEntity() && isInLoginWhiteList();
}

/** “用户主体”和“产品主体“相匹配。开关关闭时候，这个方法永远返回true */
export function userEntityAndProductEntityIsMatch(): boolean {
  return commonUserEntityAndProductEntityIsMatch(entitySwitch, userEntity, productEntity);
}

/**
 * @name: 【用户主体】与【当前域名】是否一致
 * @param {Entity} entity
 * @return {*}
 */
export function userEntityAndDomainIsMatch(entity?: Entity): boolean {
  const { isJingJi, isShouXin } = checkUserEntity(entity);
  return (isJingJi && isCN()) || (isShouXin && isShouxin());
}

/**
 * @name: 【产品主体】与【当前域名】是否一致
 * @param {Entity} entity
 * @return {*}
 */
export function productEntityAndDomainIsMatch(entity?: Entity): boolean {
  const { isJingJi, isShouXin } = checkProductEntity(entity);
  return (isJingJi && isCN()) || (isShouXin && isShouxin());
}

/**
 * 该方法为了应对一种场景：入口页面的路由在白名单中，前端会告诉bff/start接口让他放弃对主体的校验，但是当我们使用histroy api跳转路由到一个非白名单页面的时候，产品逻辑是希望bff/start接口能够校验出主体不一致的，然而这种场景下前端并不会重新调用bff/start接口。
 * 所以这个方法的作用是：识别上面的场景，并且在路由切换后刷新页面以重新出发bff/start。
 *
 * @export
 */
export function checkUserEntityOnHistoryChange() {
  // 只作用于白名单的路由
  if (!isInLoginWhiteList()) return;
  // @ts-ignore
  window.__YB_MALL_HISTORY__.listen(() => {
    // 白名单间的路由互跳不用做任何处理
    if (isInLoginWhiteList()) return;
    // 开关关闭不检查
    if (!getEntitySwitch()) return;
    // 没有用户主体不检查
    if (!getUserEntity()) return;
    // 用户主体和域名不一致，刷新页面
    if (
      (checkUserEntity().isJingJi && !isCN())
      || (checkUserEntity().isShouXin && !isShouxin())
    ) {
      setTimeout(() => {
        window.location.reload();
      }, 0);
    }
  });
}
/**
 * 判断是否属于元保经济
 * @returns
 */
export const isBelongToJingJi = (needJudgeSwitch = false) => {
  const isSwitchOpen = needJudgeSwitch ? getEntitySwitch() : true;
  if (isSwitchOpen && getProductEntity()) {
    const { isJingJi } = checkProductEntity();
    return isJingJi;
  }
  return isCN();
};

/**
 * 判断是否属于元保守信
 * @returns
 */
export const isBelongToShouXin = (needJudgeSwitch = false) => {
  const isSwitchOpen = needJudgeSwitch ? getEntitySwitch() : true;

  if (isSwitchOpen && getProductEntity()) {
    const { isShouXin } = checkProductEntity();
    return isShouXin;
  }
  return isShouxin();
};

/**
 * @name: 根据用户主体获取域名
 * @param {Entity} entity
 * @return {*}
 */
export const getHostByEntity = (entity?: Entity) => {
  const { cnhost, sxhost } = getHost();
  const checks = checkUserEntity(entity);
  const host = checks.isJingJi ? cnhost : sxhost;
  return host;
};

// @ts-ignore
if (!window.getEntity) {
  // @ts-ignore
  window.getEntity = function () {
    return {
      entitySwitch,
      userEntity,
      productEntity,
    };
  };
}
